.component {
  position: relative;
  z-index: 0;

  & > .videoContainer {
    width: 100%;
    height: 100%;
  }

  & > .image {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  & > .playButtonLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  & > .fullScreenButtonLayout {
    position: absolute;
    bottom: var(--size-16);
    right: var(--size-16);
    z-index: 2;
    width: var(--size-24);
    height: var(--size-24);

    @media (--viewport-md) {
      width: var(--size-40);
      height: var(--size-40);
    }
  }
}

.player {
  & > * {
    object-fit: cover;
  }
}

.image {
  & > * {
    height: 100%;
  }
}

.videoContainer {
  &.hidden {
    display: none;
  }
}

.componentFullScreenButton {
  background: var(--color-black--20);
  color: var(--color-white);
  border-radius: 50%;
  display: none;
  transition: background var(--duration-200);

  @media (--viewport-md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:focus-visible,
  &:focus-within {
    outline: 2px solid var(--secondary-element-color);
    outline-offset: 2px;
  }

  &:hover,
  &:focus {
    background: var(--color-gray-900);
  }
}
