.cta {
  padding: var(--size-48) var(--size-16);
  background-color: var(--color-gray-100);

  & > .inner {
    max-width: 500px;
    margin: auto;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-48);

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.content {
  color: var(--color-blue-500);

  & > .headingLayout {
    margin-bottom: var(--size-20);
  }
}
