.component {
  display: flex;
  align-items: center;
  justify-content: center;

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.icon {
  border: 1px solid color-mix(in srgb, currentColor 20%, transparent);
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border var(--duration-200) var(--ease-in-out);

  &:hover {
    box-shadow: 0 0 1px 0 var(--color-white) inset, 0 0 1px 0 var(--color-white);
    border: 1px solid currentColor;
  }
}
