.component {
  z-index: 0;
  position: relative;

  & > .overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .playButtonLayout {
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  & > .questionSlot {
    z-index: 3;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  & > .customControlsLayout {
    height: 46px;
    width: 100%;
    position: absolute;
    right: var(--size-16);
    top: var(--size-16);
    z-index: 2;
  }

  & > .closeButtonLayout {
    height: 46px;
    width: 46px;
    position: absolute;
    left: var(--size-16);
    top: var(--size-16);
    z-index: 3;
  }
}

.componentCustomControls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--size-4);

  & > .customControlButtonLayout {
    height: 46px;
    width: 46px;
  }
}

.componentCustomControlButton {
  background: var(--color-black--10);
  backdrop-filter: blur(var(--size-blur-10));
  border-radius: var(--radius-4);
  display: grid;
  place-items: center;
  color: var(--color-white);

  & > .controlIconLayout {
    height: var(--size-16);
    width: var(--size-16);
  }

  &:hover {
    background-color: var(--color-black--20);
  }

  &.isActive {
    background-color: var(--color-white);
    color: var(--color-gray-900);

    &:hover {
      background: transparent;
      color: var(--color-white);
    }
  }
}

.overlay {
  background-color: var(--color-gray-900--25);
  pointer-events: auto;
}
