.top {
  padding: var(--menu-padding) 0 var(--size-80);
  background-color: var(--background-color);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--menu-padding) 0 var(--size-128);
  }
}

.cards {
  background-color: var(--background-color);
  padding-bottom: var(--size-80);

  @media (--viewport-md) {
    padding-bottom: var(--size-128);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  gap: var(--size-24);
  align-items: stretch;

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-lg) {
    grid-template-columns: repeat(3, minmax(0, 350px));
  }
}

.intro {
  background-color: var(--background-color);
  padding: var(--size-80) 0;

  @media (--viewport-md) {
    padding: var(--size-128) 0;
  }
}

.heading {
  color: var(--color-blue-500);
}

.content {
  & > .heading {
    margin-bottom: var(--size-24);
  }

  & > .introLayout {
    @media (--viewport-md) {
      max-width: 600px;
    }
  }
}
