.component {
  border-radius: var(--radius-8);
  overflow: hidden;
  background-color: var(--color-gray-800);

  & > .syntaxHighlighter {
    margin: 0 !important;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-gray-500);
  color: var(--color-white);
  padding: var(--size-8);

  & > .copyButton {
    width: var(--size-48);
    height: var(--size-48);
  }

  & > .languageTitle {
    margin: 0 var(--size-12);
  }
}

.copyButton {
  padding: var(--size-16);
  background-color: var(--color-white--10);
  border-radius: var(--radius-circular);
}
