.component {
  display: grid;
  grid-template-columns: var(--size-24) 1fr var(--size-24);
  grid-template-rows: 1fr;
  grid-template-areas:
    'image image  ...'
    '... content ... ';

  @media (--viewport-md) {
    grid-template-columns: repeat(7, 1fr);
    grid-template-areas: 'image image image content content content';
  }

  & > .image {
    grid-area: image;
  }

  & > .content {
    grid-area: content;
    align-self: center;
    margin-top: var(--size-48);

    @media (--viewport-md) {
      margin-left: var(--size-80);
      margin-top: 0;
    }

    @media (--viewport-lg) {
      margin-left: var(--size-128);
    }
  }
}

.image {
  border-radius: 0 var(--radius-4) var(--radius-4) 0;
  overflow: hidden;
}
