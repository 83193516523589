.component {
  z-index: 0;
  display: grid;
  grid-template: 1fr / 1fr;
  position: relative;

  & > .imageContainer,
  & > .containerLayout {
    grid-area: 1 / 1;
  }

  & > .imageContainer {
    width: 100%;
    height: 100lvh;
  }

  & > .containerLayout {
    z-index: 1;
    height: 100%;
  }
}

.imageContainer {
  position: relative;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--color-black-gradient);
  }
}

.container {
  height: 100% !important;

  & > .content {
    height: 100%;
    max-width: 900px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--color);
  padding: var(--size-128) 0;

  & > .pillsLayout {
    margin-top: var(--size-24);
    margin-bottom: var(--size-16);
  }

  & > .applyLayout {
    margin-top: var(--size-16);
    width: fit-content;
  }
}
