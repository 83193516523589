.component {
  display: flex;

  & > .main {
    width: 100%;
    min-height: 100%;
  }
}

.main {
  position: relative;
  z-index: 0;

  & > .loaderLayout {
    z-index: 1;
    position: absolute;
    inset: 0;
  }

  & > .errorLayout {
    width: 100%;
    height: 100%;
  }

  & > .contentLayout {
    height: 100%;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.componentContent {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;

  &.showSideBar {
    display: grid;
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "navigation"
      "aside"
      "content";
    gap: var(--size-24);

    @media (--viewport-md) {
      grid-template-rows: auto 1fr;
      grid-template-columns: 3fr minmax(350px, 1fr);
      grid-template-areas:
        "navigation aside"
        "content    aside";
      gap: 0;
    }

    & > .skillsMatchNavigationLayout {
      grid-area: navigation;
    }

    & > .contentContainer {
      grid-area: content;
    }

    & > .aside {
      grid-area: aside;
      top: var(--size-24);
      margin: 0 var(--size-24) var(--size-24);

      @media (--viewport-md) {
        max-height: calc(100vh - (2 * var(--size-24) + 2 * var(--size-32)));
        margin-left: 0;
      }
    }
  }

  & > .contentContainer {
    max-width: 100%;
    height: 100%;
  }

  & > .skillsMatchNavigationLayout {
    z-index: 1;
    width: 100%;
    height: max-content;
    top: 0;
  }
}

.componentNavigation {
  position: sticky;
  display: flex;
  align-items: center;
  padding: var(--size-16) var(--size-24) var(--size-12);
  background-color: var(--background-color);

  & > .closeButtonLayout {
    flex-grow: 0;
    height: var(--size-48);
    width: var(--size-48);
  }

  & > .tabNavigation {
    flex-grow: 1;
    height: var(--size-48);
  }
}

.tabNavigation {
  display: flex;
  align-items: center;
  justify-content: center;

  & > .navListItem {
    height: 100%;
  }
}

.navListItem {
  display: flex;
}

.componentNavItem {
  padding-left: var(--size-16);
  padding-right: var(--size-16);
  border-bottom: 1px solid var(--color-gray-300);
  pointer-events: auto;

  &.isActive {
    color: var(--color-blue-500);
    border-bottom: 1px solid var(--color-blue-500);
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  overflow: hidden;
  overflow-x: visible;

  & > * {
    height: 100%;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  overflow: hidden;

  @media (--viewport-md) {
    position: sticky;
  }

  & > .mySelectionLayout {
    height: 100%;
  }
}

.finishButtonContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding: var(--size-12) var(--size-24) 0;
}
