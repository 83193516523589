.content {
  padding: var(--menu-padding) 0 var(--size-32) 0;

  & > .labelsLayout {
    margin: var(--size-24) 0;
  }
}

.title {
  display: flex;
  flex-direction: column;
  color: var(--color-blue-500);

  & > .branch {
    order: 1;
  }

  & > .headingLayout {
    order: 2;
  }
}

.branch {
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-32);
  color: var(--color-gray-900);
  font-style: italic;
}
