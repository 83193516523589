.componentUnordered {
  list-style-type: disc;
}

.componentOrdered {
  list-style-type: decimal;
}

.componentBase {
  list-style-position: outside;
  padding: 0 var(--size-24);
  line-height: var(--line-height-text);
}
