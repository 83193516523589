.page {
  --color-background: var(--color-white);
  --color-text: var(--color-black);
  --grids-gap: var(--size-8);
}

.section {
  background-color: var(--background-color);
  color: var(--accent-color);
}

.componentGridThree {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: 0.25fr 1fr 0.75fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'tile-1 tile-1 ...'
    '...    tile-3 tile-2';
  align-items: stretch;
  gap: var(--size-16);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 0.6fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'tile-1 tile-2'
      'tile-1 tile-3';
  }

  & > * {
    width: 100%;
    height: 100%;

    &:nth-of-type(1) {
      grid-area: tile-1;
    }

    &:nth-of-type(2) {
      grid-area: tile-2;
      z-index: -1;
      margin-top: calc(-1 * var(--size-40));

      @media (--viewport-lg) {
        margin-top: 0;
      }
    }

    &:nth-of-type(3) {
      grid-area: tile-3;
    }
  }
}

.componentGridFour {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: var(--size-16);
  grid-template-areas:
    'tile-1 tile-1  tile-1  tile-1   tile-1   tile-1  ...      ...       ...      ...'
    'tile-1 tile-1  tile-1  tile-1   tile-1   tile-1  tile-2   tile-2    tile-2   tile-2'
    '...    tile-3  tile-3  tile-3   tile-4   tile-4  tile-4   tile-4    tile-4   ...'
    '...    ...     ...     ...      tile-4   tile-4  tile-4   tile-4    tile-4   ...';

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr 1fr 0.25fr;
    grid-template-rows: 1fr 1fr 0.25fr 0.75fr;
    gap: var(--size-16);
    grid-template-areas:
      'tile-1 tile-1  tile-2  ...'
      'tile-1 tile-1  tile-4  tile-4'
      '...    tile-3  tile-4  tile-4'
      '...    tile-3  ...     ...';
  }

  & > *:nth-of-type(1) {
    grid-area: tile-1;
    align-self: end;
  }

  & > *:nth-of-type(2) {
    grid-area: tile-2;
  }

  & > *:nth-of-type(3) {
    grid-area: tile-3;
    z-index: -1;
  }

  & > *:nth-of-type(4) {
    grid-area: tile-4;
  }
}

.itemGridThree {
  transition: transform var(--duration-450) var(--ease);

  &:nth-of-type(2) {
    transform: translate(-40%, -20%);
  }

  &:nth-of-type(3) {
    transform: translate(-20%, -30%);

    @media (--viewport-md) {
      transform: translate(-130%, -30%);
    }
  }

  &:nth-of-type(4) {
    transform: translate(-10%, -10%);

    @media (--viewport-md) {
      transform: translate(-40%, 30%);
    }

    & > * {
      @media (--viewport-lg) {
        height: 100%;
      }
    }
  }

  &.compact {
    transform: translate(0, 0);
  }
}

.itemGridFour {
  transition: transform var(--duration-450) var(--ease);

  &:nth-of-type(2) {
    transform: translate(-40%, -20%);
  }

  &:nth-of-type(3) {
    transform: translate(-20%, -30%);

    @media (--viewport-md) {
      transform: translate(-130%, -30%);
    }
  }

  &:nth-of-type(4) {
    transform: translate(-10%, -10%);

    @media (--viewport-md) {
      transform: translate(-40%, 30%);
    }

    & > * {
      @media (--viewport-lg) {
        height: 100%;
      }
    }
  }

  &.compact {
    transform: translate(0, 0);
  }
}
