.component {
  display: flex;
  gap: var(--size-12);
}

.componentSliderButton {
  &:first-of-type {
    transform: rotate(180deg);
  }

  & > .buttonIconLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}
