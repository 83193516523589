.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-32);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    gap: var(--size-16);
  }

  & > .imageContainer {
    order: -1;

    @media (--viewport-lg) {
      order: 1;
    }
  }
}

.imageContainer {
  position: relative;

  & > .image {
    top: var(--menu-padding);
  }
}

.image {
  border-radius: var(--radius-4);
  overflow: hidden;
  position: sticky;

  & > .captionLayout {
    margin-top: var(--size-16);
  }
}
