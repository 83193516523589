.component {
  z-index: 0;
  position: relative;
  padding: var(--size-24);
  color: var(--color-white);
  background-color: var(--color-blue-900);
  overflow-y: auto;
  border-radius: var(--radius-8);

  & > .loaderLayout {
    z-index: 1;
    position: absolute;
    inset: 0;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
  padding-bottom: var(--size-12);

  & > .selectedItemLayout {
    width: 100%;
  }
}

.componentSelectionList {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.selectionListsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  & > .selectionListTitle {
    margin-top: var(--size-16);
  }
}

.selectionListTitle {
  font-size: var(--font-size-24);
  font-size: var(--font-size-22-25);
  font-style: italic;
  letter-spacing: -1px;
}

.componentRemoveMySelectionButton {
  height: var(--size-48) !important;
  background-color: var(--secondary-background-color);
  border-radius: var(--radius-12);
}
