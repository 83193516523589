@value _previewHeight: 70px;
@value _aspectRatio: 1.78;

:export {
  previewHeight: _previewHeight;
  aspectRatio: _aspectRatio;
}

.component {
  --padding: 4px;
  --preview-height: _previewHeight;
  --aspect-ratio: _aspectRatio;

  display: grid;
  align-items: center;
  grid-template-rows: var(--preview-height);
  grid-template-areas: 'video text';
  border: 1px solid var(--color-white--10);
  background-color: var(--color-gray-900--25) !important;
  backdrop-filter: blur(var(--size-12));
  transform-origin: bottom left;
  border-radius: var(--size-12);
  padding: var(--padding);
  position: relative;
  z-index: 0;

  & > .buttonLayout {
    z-index: 2;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    position: static;
    height: 100%;
    width: 100%;
  }

  & > .videoPreviewLayout {
    grid-area: video;
    z-index: 1;
  }

  & > .videoLayout {
    grid-area: video;
    height: 100%;
    width: 100%;
    z-index: 1;

    /* Prevent confusing Safari to contain to grid-area size. */
    &:has(> *:fullscreen) {
      grid-area: unset;
      height: 100vh;
      width: 100vw;
    }
  }

  & > .textLayout {
    grid-area: text;
  }

  & > .posterLayout {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}

.componentPoster,
.componentVideo,
.componentPreview {
  overflow: hidden;
  aspect-ratio: var(--aspect-ratio);
  border-radius: calc(var(--size-12) - var(--padding));
  background-color: var(--primary-element-color);
}

.componentVideo {
  /* This makes the transition between the preview and fullscreen smoother. */
  will-change: width, height;
  transition:
    width var(--duration-450) var(--ease-in-out),
    height var(--duration-450) var(--ease-in-out);

  /* This prevents Safari from keeping the video at the original preview size. */
  & > *:fullscreen {
    height: 100vh !important;
    width: 100vw !important;
  }
}

.componentButton {
  --button-size: calc(var(--preview-height) * 0.65);

  display: grid;
  position: relative;
  transition: transform var(--duration-200) var(--ease-in-out);
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    inset: 0;
  }

  & > .buttonInner {
    justify-self: center;
    align-self: center;
    height: var(--button-size);
    width: var(--button-size);
  }
}

.buttonInner {
  display: grid;
  place-content: center;
  border-radius: 50%;
  backdrop-filter: blur(var(--size-4));
  background-color: var(--color-black--10);
  color: var(--primary-element-color);
}

.componentText {
  overflow: hidden;
  color: var(--color-white);

  & > .textInner {
    width: 22ch;
  }
}

.textInner {
  user-select: none;
  text-wrap: balance;
}
