.page {
  & > .tileSliderContainerLayout {
    overflow: hidden;
    margin: var(--size-80) 0;

    @media (--viewport-md) {
      margin: 0;
    }
  }

  & > .interactiveVideoLayout {
    margin-block: var(--size-80);
  }

  & > .procedureSliderLayout {
    margin-block: var(--size-80);
  }

  & > .formLayout {
    margin-top: var(--size-80);
  }
}

.componentApplicationForm {
  padding: var(--size-80) 0 var(--size-32);
  background-color: var(--background-color);

  @media (--viewport-lg) {
    padding: var(--size-164) 0;
  }
}

.bottomAside {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-lg) {
    position: sticky;
  }
}

.form {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--size-16);

  @media (--viewport-lg) {
    grid-template-columns: 2fr 1fr;
  }

  & > .bottomAside {
    @media (--viewport-lg) {
      align-self: flex-start;
      top: var(--size-96);
    }
  }
}

.formTitle {
  color: var(--accent-color);
  text-align: center;

  & > .formHeadingLayout {
    margin-bottom: var(--size-32);

    @media (--viewport-md) {
      margin-bottom: var(--size-80);
    }
  }
}

.componentContentGrid {
  display: grid;
  position: relative;
  grid-template-areas:
    'header'
    'grid'
    'body-text';
  grid-template-columns: minmax(0, 1fr);
  gap: var(--size-16);

  @media (--viewport-md) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      'header     grid'
      'body-text  grid';
    gap: var(--size-32);
  }

  @media (--viewport-lg) {
    gap: var(--size-64);
  }

  & > .headerSection {
    grid-area: header;
  }

  & > .content {
    grid-area: body-text;
  }

  & > .tileGridLayout {
    grid-area: grid;
    height: fit-content;
    top: 0;

    @media (--viewport-md) {
      margin: 0 var(--size-40) var(--size-32);
      height: 800px !important;
      top: var(--size-32);
    }

    @media (--viewport-lg) {
      top: var(--size-128);
    }
  }
}

.content {
  padding-right: var(--size-32);
}

.description {
  & > .whatWeOfferLayout {
    margin-bottom: var(--size-24);
  }
}
