.component {
  background-color: var(--background-color);
  padding: var(--size-24);
  border-radius: var(--radius-4);
  display: grid;
  grid-template-columns: 1fr;

  @media (--viewport-lg) {
    padding: var(--size-64) var(--size-64) 0 var(--size-64);
    grid-template-columns: 1fr 1fr;
    gap: var(--size-128);
  }
}

.componentContentAndBottom {
  & > .bottom {
    margin-top: var(--size-64);

    @media (--viewport-lg) {
      margin-bottom: var(--size-64);
      margin-top: var(--size-128);
    }
  }
}

.componentHeadingAndImage {
  & > .heading {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin-bottom: var(--size-32);
    }
  }
}

.heading {
  color: var(--accent-color);
}

.image {
  display: none;
  border-radius: var(--radius-12);
  overflow: hidden;

  @media (--viewport-lg) {
    display: block;
  }
}

.text {
  padding: var(--size-64) var(--size-64) var(--size-64) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label {
  font-style: italic;
}

.bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border-top: 1px solid var(--border-color);
  padding-top: var(--size-24);
  gap: var(--size-24);

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: center;
    padding-top: var(--size-32);
    gap: var(--size-12);
  }
}
