.component {
  position: sticky;
  top: 0;
}

.wrapper {
  position: relative;
  z-index: 0;

  & > .imageContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.inner {
  & > .content {
    min-height: 100vh;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--color);
  padding-bottom: var(--size-128);

  & > .titleLayout {
    margin-bottom: var(--size-16);

    @media (--viewport-lg) {
      margin-bottom: var(--size-48);
    }
  }

  & > .info {
    width: 100%;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "intro";
  gap: var(--size-128);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "metadata intro";
  }

  & > .metadata {
    grid-area: metadata;
  }

  & > .intro {
    grid-area: intro;
  }
}

.metadata {
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.intro {
  font-size: var(--font-size-18);
  color: var(--color-white);
  line-height: var(--line-height-text);
}

.imageContainer {
  position: relative;
  z-index: 0;

  & > .imageLayout {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--color-black-gradient);
  }
}
