.componentDesktop,
.componentMobile {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 var(--size-16) var(--size-24);
  border-top: 1px solid var(--color-gray-200);

  & > .listItemDesktop {
    width: 100%;
  }
}

.listItemDesktop {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 var(--size-16);

  @media (--viewport-xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-xxl) {
    grid-template-columns: repeat(3, 1fr);
  }

  &:has(+ *) {
    border-bottom: 1px solid var(--color-gray-200);
  }

  & > * {
    width: 100%;
  }
}

.listItemMobile {
  &:has(+ *) {
    border-bottom: 1px solid var(--color-gray-200);
  }
}

.componentItem {
  --arrow-opacity: 0;
  --arrow-transformX: calc(-1 * var(--size-16));
  --subtitle-grid-rows: 0fr;

  z-index: 0;
  display: grid;
  grid-template-columns: var(--size-80) 1fr;
  align-items: center;
  padding: var(--size-16) 0;
  gap: var(--size-16);
  position: relative;

  &:hover,
  &:focus-within {
    --arrow-opacity: 1;
    --arrow-transformX: var(--size-8);
    --subtitle-grid-rows: 1fr;
  }

  & > .image {
    width: var(--size-80);
    height: var(--size-80);
  }

  & > .itemDetailsLayout {
    position: static;
    width: 100%;
  }

  & > .arrowIconContainer {
    z-index: -1;
    position: absolute;
    width: var(--size-40);
    height: var(--size-40);
    right: var(--size-16);
  }
}

.arrowIconContainer {
  opacity: var(--arrow-opacity);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray-900);
  border-radius: var(--radius-circular);
  transform: translateX(var(--arrow-transformX));
  transition: var(--duration-200) var(--ease);
  transition-property: opacity, transform;
}

.image {
  border-radius: var(--radius-4);
  overflow: hidden;

  & > .imageLayout {
    height: 100%;
  }
}

.componentItemDetails {
  position: relative;
  padding-right: var(--size-40);

  & > .title {
    position: static;
  }
}

.title {
  position: relative;
  display: block;
  font-size: var(--font-size-20);
  font-style: italic;

  & > .anchor {
    position: static;
  }
}

.anchor {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.subtitleContainer {
  display: grid;
  grid-template-rows: var(--subtitle-grid-rows);
  transition: grid-template-rows var(--duration-450) var(--ease);

  & > * {
    min-height: 0;
  }
}

.subtitle {
  font-size: var(--font-size-16);
  color: var(--color-blue-500);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
