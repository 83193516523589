.component {
  z-index: 0;
  position: relative;
  border-radius: var(--radius-4) var(--radius-4) 0 0;
  overflow: hidden;
  color: var(--color-white);

  & > .imageContainer {
    width: 100%;
    height: 590px;

    @media (--viewport-sm) {
      max-height: 780px;
      height: 100%;
    }
  }

  & > .containerLayout {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 35px;
  }
}

.heading {
  & > * {
    max-width: 80%;
    width: 100%;
  }
}

.imageContainer {
  z-index: 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 75%;
    bottom: 0;
    left: 0;
    background: var(--color-black-gradient);
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}
