.component {
  position: relative;
  background-color: var(--background-color);
  color: var(--color);
  padding: calc(var(--menu-padding-sm) + var(--size-32)) 0 var(--size-48);
  z-index: 0;

  & > .swirlLayout {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  @media (--viewport-md) {
    padding: calc(var(--menu-padding-sm) + var(--size-80)) 0 var(--size-80);
  }
}

.content {
  display: grid;
  gap: var(--size-48);
}

.categories {
  display: grid;
  gap: var(--size-8);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
