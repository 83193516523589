.componentBase {
  padding-block: var(--size-96);

  @media (--viewport-lg) {
    padding-block: var(--size-128);
  }
}

.gridWithTextContainer {
  display: flex;
  align-items: center;
  gap: var(--size-40);
  flex-direction: column;

  @media (--viewport-lg) {
    flex-direction: row;
    gap: var(--size-48);
  }

  @media (--viewport-xl) {
    gap: var(--size-96);
  }

  & > :first-child {
    width: 100%;

    @media (--viewport-lg) {
      width: 50%;
    }
  }

  & > :nth-child(2) {
    width: 100%;

    @media (--viewport-lg) {
      width: 50%;
    }
  }

  & > .children:first-child,
  & > .children:nth-child(2) {
    width: 100%;

    @media (--viewport-sm) {
      width: 70%;
    }

    @media (--viewport-lg) {
      width: 50%;
    }
  }
}

.componentContent {
  & > .titleLayout {
    margin-bottom: var(--size-8);
  }

  & > .descriptionLayout {
    margin-bottom: var(--size-16);
  }
}
