.component {
  display: flex;
  align-items: center;
  gap: var(--size-16);
}

.images {
  display: none;

  &.showMobile {
    display: flex;
  }

  @media (--viewport-sm) {
    display: flex;
    align-items: center;
  }

  & > * {
    width: 64px;
    height: 64px;
  }

  & > :nth-child(2) {
    margin-left: -20px;
  }
}

.imageContainer {
  border-radius: var(--radius-circular);
  overflow: hidden;
  border: 2px solid var(--color-gray-100);
}

.name {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
}
