.component {
  display: flex;
  justify-content: flex-end;

  & > .navBarContainer {
    width: max-content;

    &.menuIsFullWidth {
      width: 100%;
    }
  }
}

.navBarContainer {
  z-index: 0;
  position: relative;
  padding: 0 var(--size-16);
  border-radius: var(--radius-8);
  background-color: var(--color-white);
  box-shadow: var(--shadow-lg);
  pointer-events: auto;
}

.componentNavBar {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: var(--size-64);
  grid-template-areas: 'hamburger';
  justify-content: space-between;
  align-items: center;

  &.menuIsFullWidth {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: var(--size-64) auto;
    grid-template-areas:
      'logo hamburger'
      'menu menu';
  }

  & > .logoAndButtonsLayout {
    grid-area: logo;
    height: 100%;
  }

  & > .hamburgerAndLanguageSwitchLayout {
    grid-area: hamburger;
    height: 100%;
  }

  & > .navigationLayout {
    grid-area: menu;
    max-height: calc(100dvh - 112px); /* 112px spacing with window at the bottom */
  }
}

.componentHamburgerAndLanguageSwitch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.componentLogoAndButtons {
  display: flex;
  align-items: center;

  & > .logoExpandLayout {
    flex-shrink: 0;
  }

  & > .navigationLayout {
    height: 100%;
  }
}

.componentNavigation {
  background-color: var(--color-white);
  overflow-y: auto;
}

.skillsButtonContainer {
  & > .skillsButtonLayout {
    width: 100%;
    margin: var(--size-24) 0;
  }
}

.logoContainer {
  & > .logoLayout {
    width: 120px;
  }
}

.fixedButtonContainer {
  white-space: nowrap;
}

.componentMenu {
  display: grid;
  grid-template-columns: 1fr;
}

.menuItem {
  & > * {
    width: 100%;
  }
}

.componentMenuLink,
.componentSubmenuButton {
  display: block;
  padding: var(--size-24) var(--size-16);
  border-top: 1px solid var(--color-gray-100);
  font-size: var(--font-size-18);
  font-style: italic;
  text-align: left;

  &.isActive {
    text-decoration: underline;
  }
}

.componentBackButton {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  color: var(--color-blue-700);
  white-space: nowrap;

  & > .icon {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-left: var(--size-16);
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-900);
  border: 1px solid var(--color-gray-900);
  border-radius: var(--radius-circular);
  transform: rotate(-180deg);
  background-color: var(--color-white);
}

.navigationButtons {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-8);
}
