.componentSkillsMatchPortal {
  pointer-events: auto;
  overflow: hidden;
  padding: var(--size-8);
  background-color: var(--color-black--20);

  @media (--viewport-md) {
    padding: var(--size-32);
  }

  & > .skillsMatchWrapper {
    height: 100%;
    width: 100%;
    max-width: var(--container-flexible-width-lg);
    margin: 0 auto;
  }
}

.skillsMatchWrapper {
  display: grid;
  position: relative;
  overflow: hidden;
}

.skillsMatchContainer {
  z-index: 0;
  position: relative;
  background-color: var(--color-gray-100);
  overflow-y: auto;
  border-radius: var(--radius-20);

  & > .skillsMatchLayout {
    height: fit-content;
    min-height: 100%;
  }
}
