.componentBase {
  color: var(--color-white);
  border-radius: var(--radius-4);
  display: grid;
  place-items: center;

  & > .closeIconLayout {
    height: var(--size-16);
    width: var(--size-16);
  }
}

.component {
  background-color: var(--color-blue-900);

  &:hover {
    background-color: var(--color-blue-700);
  }
}

.componentGray {
  background-color: var(--color-gray-900--80);

  &:hover {
    background-color: var(--color-gray-900);
  }
}

.componentWhite {
  color: var(--color-blue-900);

  &:hover {
    color: var(--color-white);
    background-color: var(--color-gray-400);
  }
}
