.component {
  background-color: var(--background-color);
  padding: var(--size-48);
  border-radius: var(--radius-4);
  position: relative;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewport-md) {
    padding: var(--size-64) 0;
  }

  & > .noResultTitle {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-32);
      max-width: 350px;
    }
  }
}

.noResultTitle {
  color: var(--accent-color);
  padding: 0 var(--size-16);

  @media (--viewport-md) {
    text-align: center;
  }
}
