.componentBase {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-circular);
  color: var(--color-gray-900);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  transition: opacity var(--duration-150) var(--ease);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.isActive,
  &.isProcessing {
    color: var(--color-active);
    background-color: var(--background-color-active);
  }

  & > .iconContainer {
    width: 100%;
    height: 100%;
  }
}

.componentWhite {
  color: var(--color-gray-900);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);

  --color-active: var(--color-white);
  --background-color-active: var(--color-blue-500);
}

.componentBlue {
  color: var(--color-white);
  background-color: var(--color-blue-700);
  border: 1px solid var(--color-blue-900);
}

.componentBlack {
  color: var(--color-white);
  background-color: var(--color-gray-900);
  border: 1px solid var(--color-gray-900);
}

.componentTransparent {
  color: var(--color-white);
  background-color: transparent;
  border: 1px solid var(--color-white);

  --color-active: var(--color-blue-500);
  --background-color-active: var(--color-white);
}

.componentWithLabelBase {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-columns: 1fr var(--size-32);
  justify-content: space-between;
  align-items: center;
  gap: var(--size-12);
  padding-left: var(--size-24);
  padding-right: var(--size-8);
  border-radius: var(--radius-12);
  color: var(--color-gray-900);
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
  text-align: left;

  & > .iconCircleLayout {
    position: static;
    height: var(--size-32);
    width: var(--size-32);
  }
}

.componentWithLabelTransparent {
  color: var(--color-white);
  background: var(--color-white--20);
  backdrop-filter: blur(10px);
}

.componentWithLabelBlue {
  color: var(--color-white);
  background: var(--color-blue-900);
  border: 1px solid var(--color-white--40);
}

.componentWithLabelGray {
  color: var(--color-gray-900);
  background: var(--color-gray-200);
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-4);

  & > .loaderLayout {
    width: 100%;
    height: 100%;
  }

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}
