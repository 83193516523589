.component {
  & > .heading {
    margin-bottom: var(--size-24);
  }
}

.heading {
  color: var(--accent-color);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
}

.componentLink {
  background-color: var(--background-color);
  padding: var(--size-16);
  border-radius: var(--radius-12);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-16);

  @media (--viewport-md) {
    padding: var(--size-24);
  }

  & > .header {
    position: static;
  }

  & > .link {
    position: static;
  }
}

.link {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.title {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-20);
  font-style: italic;
}

.icon {
  border: 1px solid var(--tertiary-element-color);
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tertiary-element-background-color);

  &.fill {
    background-color: var(--tertiary-element-background-color-hover);
    color: var(--tertiary-element-color-hover);
  }
}
