.page {
  position: relative;
  z-index: 0;

  & > .heroLayout {
    width: 100%;
    height: 500svh;
  }

  & > .contentWavemakersLayout {
    z-index: 1;
  }
}
