.component {
  display: flex;
  align-items: center;
  padding: var(--size-16);
  background-color: var(--background-color);
  color: var(--color);
  border-radius: var(--radius-4);

  & > .button {
    margin-right: var(--size-48);
  }
}

.button {
  display: flex;

  & > .icon {
    margin-right: var(--size-8);
    width: 20px;
    height: 20px;
  }
}

.icon {
  border: 1px solid var(--color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktopText {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.mobileText {
  display: block;

  @media (--viewport-md) {
    display: none;
  }
}
