.component {
  --header-margin: var(--size-16);

  & > .containerLayout {
    margin: var(--size-32) 0;

    @media (--viewport-lg) {
      margin: var(--size-48) 0;
    }
  }
}

.contentContainer {
  display: grid;
  line-height: var(--line-height-text);
  grid-template-columns: 1fr;
  grid-template-areas:
    'filters'
    'main';
  grid-gap: var(--size-32);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'filters main';
    grid-gap: var(--size-16);
    grid-template-rows: 1fr;
  }

  & > .filters {
    grid-area: filters;
  }

  & > .results {
    grid-area: main;
  }
}

.results {
  & > .resultsHeader {
    margin: 0 0 var(--header-margin);

    @media (--viewport-lg) {
      margin: var(--header-margin) 0;
    }
  }

  & > .pagination {
    margin: var(--size-32) 0 0;

    @media (--viewport-md) {
      margin: var(--size-48) 0 0;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.filters {
  & > .filtersHeader {
    margin: var(--header-margin) 0;
  }
}

.filtersContainer {
  background-color: var(--background-color);
  border-radius: var(--radius-4);
}

.filtersHeader,
.resultsHeader {
  font-size: var(--font-size-18);
}

.highlight {
  color: var(--accent-color);
}
