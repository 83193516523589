.component {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;
  transition: gap var(--duration-200) var(--ease);
  transition-delay: var(--duration-200);

  &.isActive {
    transition-delay: 0s;
    gap: var(--size-16);
  }

  & > .buttonLayout {
    z-index: 1;
    top: 0;
    width: 100%;
  }
}

.ExpanderHeader {
  display: flex;
  gap: var(--size-8);

  & > .counterLayout {
    height: var(--size-20);
    width: var(--size-20);
  }
}

.iconContainer {
  transform: rotate(0);
  transition: transform var(--duration-150) var(--ease);

  &.isActive {
    transform: rotate(-180deg);
  }
}

.componentButton {
  display: inline-flex;
  align-items: center;
  gap: var(--size-16);
}

.componentCounter {
  background-color: var(--color-blue-500);
  border-radius: var(--radius-4);
  font-size: var(--font-size-12);
  display: grid;
  place-items: center;
}
