.component {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'content'
    'image';
  gap: var(--size-16);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'content image';
  }

  & > .content {
    grid-area: content;
  }

  & > .image {
    grid-area: image;
  }
}

.content {
  background-color: var(--background-color);
  color: var(--accent-color);
  border-radius: var(--radius-4);
  padding: var(--size-24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewport-md) {
    padding: var(--size-40);
  }

  & > .headingLayout {
    margin-bottom: var(--size-48);

    @media (--viewport-lg) {
      margin-bottom: 0;
    }
  }
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--color);
  flex-direction: column;

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: flex-end;
    gap: var(--size-16);
  }

  & > .label {
    max-width: 200px;
    width: 100%;
    margin-bottom: var(--size-16);

    @media (--viewport-lg) {
      margin-bottom: 0;
    }
  }
}

.label {
  font-style: italic;
}

.image {
  border-radius: var(--radius-4);
  overflow: hidden;

  & > * {
    height: 100%;
    max-height: 360px;

    @media (--viewport-md) {
      max-height: 480px;
    }
  }
}
