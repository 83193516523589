.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  position: relative;
  z-index: 0;

  & > .loaderLayout {
    z-index: 1;
    position: absolute;
    inset: 0;
  }

  & > .errorLayout {
    height: 100%;
    width: 100%;
  }
}

.componentSuggestedSkills {
  color: var(--color-blue-500);

  & > *:not(:last-child) {
    margin-bottom: var(--size-16);
  }
}

.listsWrapper {
  @media (--viewport-md) {
    padding: 0 var(--container-padding);
  }

  & > .skillsContainer {
    @media (--viewport-md) {
      max-width: var(--container-max-width-md);
      margin: 0 auto;
    }
  }
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);
  padding: var(--size-8) var(--size-24) var(--size-48);

  & > .searchBoxLayout {
    width: 100%;
    margin-bottom: var(--size-24);
  }
}

.componentList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: var(--size-8);
  padding-bottom: var(--size-8);
}
