.component {
  display: grid;
  gap: var(--size-16);
  grid-template-columns: 0.6fr 0.4fr 0.4fr;
  grid-template-areas:
    '...     photo-1 ...    '
    'photo-0 photo-1 ...    '
    'photo-0 photo-2 photo-2'
    'photo-0 photo-2 photo-2'
    'photo-0 photo-2 photo-2'
    '...     photo-2 photo-2';

  @media (--viewport-lg) {
    grid-template-columns: 0.6fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.6fr 0.4fr 0.4fr;
    grid-template-areas:
      '...     photo-1 ...     photo-3 photo-3     ...     ...     ...     ...'
      'photo-0 photo-1 ...     photo-3 photo-3     photo-5 ...     photo-7 photo-7'
      'photo-0 photo-1 ...     photo-3 photo-3     photo-5 photo-6 photo-7 photo-7'
      'photo-0 photo-2 photo-2 photo-3 photo-3     photo-5 photo-6 photo-7 photo-7'
      'photo-0 photo-2 photo-2 photo-3 photo-3     ...     photo-6 photo-7 photo-7'
      'photo-0 photo-2 photo-2 ...     photo-4     ...     photo-6 photo-7 photo-7'
      '...     photo-2 photo-2 ...     photo-4     ...     ...     ...     ...';
  }

  & > [data-index="0"] {
    grid-area: photo-0;
  }

  & > [data-index="1"] {
    grid-area: photo-1;
  }

  & > [data-index="2"] {
    grid-area: photo-2;
  }

  & > [data-index="3"] {
    grid-area: photo-3;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > [data-index="4"] {
    grid-area: photo-4;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > [data-index="5"] {
    grid-area: photo-5;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > [data-index="6"] {
    grid-area: photo-6;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }

  & > [data-index="7"] {
    grid-area: photo-7;
    display: none;

    @media (--viewport-lg) {
      display: block;
    }
  }
}

.image {
  border-radius: var(--radius-12);
  overflow: hidden;

  & > * {
    width: 100%;
  }
}
