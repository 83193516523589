.componentOne {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: var(--size-16);

  @media (--viewport-md) {
    grid-template-columns: 1fr 4fr 1fr;
  }

  & > * {
    width: 100%;
    height: 100%;

    @media (--viewport-md) {
      grid-column: 2;
    }
  }
}

.componentTwo {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'tile-1 tile-2 tile-2';
  align-items: stretch;
  gap: var(--size-16);

  & > * {
    width: 100%;
    height: 100%;

    &:nth-of-type(1) {
      grid-area: tile-1;
      height: 50%;

      @media (--viewport-lg) {
        height: fit-content;
      }
    }

    &:nth-of-type(2) {
      grid-area: tile-2;
    }
  }
}

.componentThree {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'tile-1 ...'
    'tile-3 tile-2';
  align-items: stretch;
  gap: var(--size-16);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'tile-2 tile-1'
      'tile-3 tile-1';
  }

  & > * {
    &:nth-of-type(1) {
      grid-area: tile-1;
    }

    &:nth-of-type(2) {
      grid-area: tile-2;
      z-index: -1;
      margin-top: -60px;

      @media (--viewport-lg) {
        margin-top: 0;
      }
    }

    &:nth-of-type(3) {
      grid-area: tile-3;
    }
  }
}

.componentFour {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: 4fr 2fr 3fr 1fr;
  grid-template-rows: repeat(4, auto);
  gap: var(--size-16);
  grid-template-areas:
    'tile-1   tile-1   ...      ...'
    'tile-1   tile-1   tile-2   tile-2'
    'tile-3   tile-4   tile-4   ...'
    '...      tile-4   tile-4   ...';

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr 1fr 0.25fr;
    grid-template-rows: 1fr 1fr 0.25fr 0.75fr;
    gap: var(--size-16);
    grid-template-areas:
      'tile-1 tile-1  tile-2  ...'
      'tile-1 tile-1  tile-4  tile-4'
      '...    tile-3  tile-4  tile-4'
      '...    tile-3  ...     ...';
  }

  & > * {
    width: 100%;
    height: 100%;

    &:nth-of-type(1) {
      grid-area: tile-1;
    }

    &:nth-of-type(2) {
      grid-area: tile-2;
      z-index: -1;
    }

    &:nth-of-type(3) {
      grid-area: tile-3;
      z-index: -1;
    }

    &:nth-of-type(4) {
      grid-area: tile-4;
    }
  }
}

.itemTwo {
  transition: transform var(--duration-450) var(--ease);

  &:nth-of-type(1) {
    transform: translate(15%, -10%);

    & > * {
      height: 100%;
    }
  }

  &:nth-of-type(2) {
    transform: translate(-10%, 0%);
  }

  &.compact {
    transform: translate(0, 0);
  }

  & > .videoTileLayout {
    width: 100%;
    height: 100%;
  }
}

.itemThree {
  transition: transform var(--duration-450) var(--ease);

  &:nth-of-type(1) {
    transform: translate(5%, 0%);

    & > * {
      height: 100%;

      @media (--viewport-lg) {
        height: auto;
      }
    }

    @media (--viewport-lg) {
      transform: translate(-5%, 0%);
    }
  }

  &:nth-of-type(2) {
    transform: translate(-10%, -80%);

    @media (--viewport-lg) {
      transform: translate(20%, -20%);
    }

    & > * {
      height: 50%;

      @media (--viewport-lg) {
        height: 100%;
      }
    }
  }

  &:nth-of-type(3) {
    transform: translate(20%, -30%);

    @media (--viewport-lg) {
      transform: translate(20%, 30%);
    }

    & > * {
      width: 80%;
      height: 80%;
      justify-self: end;

      @media (--viewport-lg) {
        width: 100%;
        height: 100%;
        justify-self: auto;
      }
    }
  }

  &.compact {
    transform: translate(0, 0);
  }
}

.itemFour {
  transition: transform var(--duration-450) var(--ease);

  &:nth-of-type(1) {
    transform: translate(10%, 0%);

    & > * {
      width: 100%;
      height: 100%;
    }
  }

  &:nth-of-type(2) {
    transform: translate(-20%, -20%);

    @media (--viewport-lg) {
      transform: translate(-20%, 0%);
      aspect-ratio: auto;
    }

    & > * {
      width: 100%;
      height: 100%;
    }
  }

  &:nth-of-type(3) {
    transform: translate(-10%, -30%);

    @media (--viewport-lg) {
      transform: translate(-130%, -30%);
    }
  }

  &:nth-of-type(4) {
    transform: translate(0%, -10%);

    @media (--viewport-md) {
      transform: translate(-40%, 10%);
    }
  }

  &.compact {
    transform: translate(0, 0);
  }
}

.componentSkillsMatchTile {
  z-index: 0;
  position: relative;

  & > :not(.skillsMatchButtonLayout) {
    height: 100%;
  }

  & > .skillsMatchButtonLayout {
    z-index: 2;
    position: absolute;
    inset: 0;
  }
}
