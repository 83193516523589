.component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-32);

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }
}

.content {
  color: var(--accent-color);

  & > .text {
    margin-top: var(--size-16);

    @media (--viewport-md) {
      margin-top: var(--size-24);
    }
  }
}

.text {
  color: var(--color);
  font-size: var(--font-size-18);
}

.containerJobs {
  & > .jobs {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-12);
    }
  }
}

.jobs {
  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}
