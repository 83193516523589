.component {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  & > .select {
    z-index: 1;
  }

  & > .iconLayout {
    position: absolute;
    margin-right: var(--size-12);
    z-index: -1;
    right: 0;
  }
}

.select {
  padding: var(--size-12) var(--size-32) var(--size-12) var(--size-12);

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 1;
    color: var(--color-gray-900);
    cursor: default;
  }
}
