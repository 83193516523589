.component {
  position: relative;
  object-fit: cover;
  overflow: hidden;

  & > * {
    position: absolute;
    inset: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: var(--color-black-gradient);
  }

  & > .video {
    width: 100%;
    height: 100%;
  }
}

.video {
  object-fit: cover;
}
