.page {
  padding-top: var(--menu-padding);

  & > .introContainerLayout {
    margin-top: var(--size-32);

    @media (--viewport-lg) {
      margin-top: var(--size-80);
    }
  }
}

.head {
  color: var(--color-blue-500);
}

.intro {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'intro';

  @media (--viewport-lg) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: '... ... ... ... ... ... intro intro intro intro intro ...';
    grid-gap: var(--size-16);
  }

  & > .introTextLayout {
    grid-area: intro;
  }
}
