.component {
  & > .titleLayout {
    margin-bottom: var(--size-64);
  }

  & > .introLayout {
    margin: var(--size-48) 0 var(--size-80);

    @media (--viewport-md) {
      margin: var(--size-128) 0 var(--size-80);
    }
  }

  & > .relatedContentGrid {
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-48);
    }
  }
}

.componentTitle {
  color: var(--color);
  text-align: center;
  text-wrap: balance;
}
