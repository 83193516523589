.componentBase {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;

  &.justifyEnd {
    justify-content: flex-end;
  }
}

.componentVertical {
  flex-direction: column;
}
