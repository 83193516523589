.item {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-16);
  padding: var(--size-24) 0;
  font-size: var(-font-size-18);
  font-style: italic;
  border-top: 1px solid var(--hairline-color);

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.title {
  color: var(--accent-color);
}
