.component {
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-8);
  color: var(--color-blue-700);

  & > .iconLayout {
    display: flex;
    justify-content: center;
    min-width: 24px;
    height: 100%;
  }
}

.label {
  color: var(--color-gray-900);

  @media not (--viewport-xs) {
    display: none;
  }
}
