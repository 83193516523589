.component {
  & > .grid {
    height: 100%;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  gap: var(--size-16);
  place-items: center;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  & > .videoFlowLayout {
    margin-top: var(--size-16);
    max-height: 100%;

    @media (--viewport-md) {
      margin-top: 0;
    }
  }

  & > .ctaLayout {
    max-width: 100%;
    margin: var(--size-24);

    @media (--viewport-md) {
      max-width: 60%;
    }
  }
}

.componentInteractiveVideoFlow {
  position: relative;
  z-index: 0;
  border-radius: var(--radius-12);
  overflow: hidden;
  aspect-ratio: 9 / 16;
  display: grid;
  grid-template-areas: 'content';
  grid-template-rows: 1fr;

  & > .videoLayout {
    width: 100%;
    height: 100%;
    grid-area: content;
    z-index: 0;
  }

  & > .questions {
    grid-area: content;
    z-index: 2;
    align-self: flex-end;
  }
}

.questions {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
  padding: var(--size-24);
}

.componentSkillsCTA {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);
  text-align: center;
  color: var(--color-blue-500);

  @media (--viewport-md) {
    text-align: left;
    gap: var(--size-24);
  }

  & > .buttonLayout {
    width: fit-content;
    place-self: center;

    @media (--viewport-md) {
      place-self: flex-start;
    }
  }
}

.description {
  font-size: var(--font-size-16-18);
  font-style: italic;
  line-height: var(--line-height-text);
  color: var(--color-gray-900);
}

.componentNextQuestionButton {
  position: relative;
  padding: var(--size-8) var(--size-8) var(--size-8) var(--size-16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(var(--size-blur-10));
  background-color: var(--color-black--10);
  border-radius: var(--radius-4);
  color: var(--color-white);
  font-weight: var(--font-weight-600);

  & > .arrowBox {
    width: 46px;
    height: 46px;
  }
}

.arrowBox {
  color: var(--color-gray-900);
  background-color: var(--color-white);
  border: 1px solid var(--color-white);
  border-radius: var(--radius-4);
  position: relative;
  transition: background-color var(--duration-200) var(--ease-in-out);
  display: grid;
  place-items: center center;

  &.isRelativeToParent {
    position: static;
  }

  &:hover {
    color: var(--color-white);
    background-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
