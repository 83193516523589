.page {
  padding: var(--menu-padding) 0 var(--size-64);
  background-color: var(--background-color);

  & > .formLayout {
    max-width: 800px;
    margin: auto;
  }

  & > *:not(:last-child) {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin-bottom: var(--size-32);
    }
  }
}

.header {
  text-align: center;
  color: var(--color-blue-500);
}

.content {
  text-align: center;
}

.form {
  background-color: var(--color-white);
  padding: var(--size-24);
  border-radius: var(--radius-4);

  @media (--viewport-md) {
    padding: var(--size-48);
  }
}
