.cta {
  background-color: var(--background-color);
  color: var(--color);
  padding: var(--size-16);
  border-radius: var(--radius-4);
  display: flex;
  align-items: center;
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-48) var(--size-32);
  }

  --hover-background-icon: transparent;
  --hover-icon-color: var(--accent-color);

  &:hover {
    --hover-background-icon: var(--primary-element-background-color);
    --hover-icon-color: var(--background-color);
  }

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
    flex-shrink: 0;
    margin-left: var(--size-16);
    background-color: var(--hover-background-icon);
    color: var(--hover-icon-color);
  }

  & > .button {
    position: static;
  }
}

.content {
  & > .headingLayout {
    margin-bottom: var(--size-16);
  }
}

.icon {
  border: 1px solid currentColor;
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
}
