.component {
  background-color: var(--background-color);
  color: var(--accent-color);
  padding: var(--size-24);
  border-radius: var(--radius-12);
  position: relative;
  z-index: 0;
  transition: box-shadow var(--duration-200) var(--ease-in-out);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;

  & > .top {
    margin-bottom: var(--size-8);
    align-self: start;
  }

  & > .pillsLayout {
    margin-top: var(--size-16);
  }

  & > .type {
    margin-bottom: var(--size-8);
  }

  & > .linkLayout {
    position: static;
    z-index: 1;
  }

  & > .introLayout {
    margin-bottom: var(--size-32);
    margin-top: var(--size-16);
  }

  &:hover {
    box-shadow: var(--shadow-md);
  }

  & > .jobInfoLayout {
    align-self: end;
  }
}

.top {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.type {
  font-style: italic;
  color: var(--color);
}

.componentPills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.pill {
  border: 1px solid var(--hairline-color);
  border-radius: var(--radius-20);
  color: var(--color);
  font-size: var(--font-size-14);
  padding: var(--size-4) var(--size-12);
}

.componentJobInfo {
  border-radius: var(--radius-4);
  padding: var(--size-16);
  background-color: var(--shade-color);
  color: var(--color);

  & > :not(:last-child) {
    margin-bottom: var(--size-8);
  }
}

.componentListItem {
  display: flex;
  align-items: center;
  gap: var(--size-8);
}

.componentLink {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  & > .linkLabel {
    width: 0;
  }
}

.linkLabel {
  display: none;
}

.componentStatus {
  background-color: var(--shade-color);
  color: var(--color);
  padding: var(--size-4) var(--size-12);
  display: flex;
  align-items: center;
  gap: var(--size-4);

  & > .light {
    width: var(--size-8);
    height: var(--size-8);
  }
}

.light {
  border-radius: 50%;
  display: block;
}
