.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  &.isLoading {
    opacity: 0.5;
  }
}

.seperator {
  background-color: var(--hairline-color);
}

.metadata {
  display: flex;
  align-items: center;
  gap: var(--size-8);
  flex-wrap: wrap;

  @media (--viewport-md) {
    gap: var(--size-16);
  }

  & > .seperator {
    width: 1px;
    height: 24px;
  }

  & > .authorsLayout {
    flex-shrink: 0;
  }
}

.componentCategories {
  display: flex;
  flex-wrap: wrap;
}

.category {
  font-style: italic;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: "•";
      color: var(--color);
      margin: 0 var(--size-8);
    }
  }
}

.componentAuthors {
  font-size: var(--font-size-14);
  display: flex;
  align-items: center;
  gap: var(--size-12);
}

.avatars {
  display: none;

  @media (--viewport-md) {
    display: flex;
    align-items: center;
  }

  & > .avatar {
    width: var(--size-48);
    height: var(--size-48);
  }

  & > :not(:first-child) {
    margin-left: -20px;
  }
}

.avatar {
  border: 2px solid var(--color-gray-100);
  border-radius: 50%;
  overflow: hidden;
}

.title {
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-32);
  line-height: var(--line-height-heading);
  font-style: italic;
  position: relative;
}

.blueTitle {
  color: var(--accent-color);
}

.componentTechblogCard {
  & > .categoriesLayout {
    margin-bottom: var(--size-4);

    @media (--viewport-md) {
      margin-bottom: var(--size-8);
    }
  }

  & > .title {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin-bottom: var(--size-20);
    }
  }
}

.componentJobAlertCard {
  color: var(--color);

  & > .title {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin-bottom: var(--size-20);
    }
  }
}
