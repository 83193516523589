.loaderContainer {
  display: flex;
  justify-content: center;
}

.component {
  background-color: var(--background-color);
  border-radius: var(--radius-circular);
  display: inline-flex;
  justify-content: center;
  padding: var(--size-4);

  & > .loaderIconLayout {
    width: 100%;
    height: 100%;
  }
}

.loaderIcon {
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-circular);
    border: var(--size-4) solid var(--secondary-accent-color);
    border-left-color: transparent;
    border-right-color: transparent;
    animation: icon-animation 1.2s linear infinite;
  }
}

@keyframes icon-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
