.component {
  & > .container {
    margin: 0 auto;
    max-width: 70ch;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--accent-color);
  font-size: var(--font-size-18);
  line-height: var(--line-height-text);

  & > .headingLayout {
    margin-bottom: var(--size-16);
  }

  & > .buttonLayout {
    max-width: max-content;
    margin-top: var(--size-24);
  }
}
