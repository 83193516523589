.componentBase {
  font-size: var(--font-size-18);
  color: var(--color);

  & > .headingLayout,
  & > .subheading {
    margin-top: var(--size-48);
    margin-bottom: var(--size-24);
  }

  & > .paragraph,
  & > .listLayout {
    margin: 1em 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.paragraph {
  line-height: var(--line-height-text);
}

.componentIntro {
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-25);
  line-height: var(--line-height-text);
  font-style: italic;
}

.componentCard {
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-text-sm);
}

.subheading {
  font-size: var(--font-size-28);
  font-size: var(--font-size-28-32);
  font-style: italic;
  line-height: var(--line-height-heading);
  color: var(--accent-color);
}

/* stylelint-disable kaliber/selector-policy */
.subheading > strong {
  font-weight: inherit;
}
/* stylelint-enable kaliber/selector-policy */
