.component {
  pointer-events: none;
  display: grid;
  place-items: center;
  background: transparent;
  backdrop-filter: blur(0);
  transition:
    backdrop-filter var(--duration-200) var(--ease),
    background var(--duration-450) var(--ease);

  &.isFetching {
    pointer-events: auto;
    background: var(--color-black--10);
    backdrop-filter: blur(5px);
  }

  & > .loaderLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}
