.componentDesktopScrollingSlider {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--size-16);

  & > .contentContainer {
    top: 0;
  }

  & > .imageContainer {
    height: var(--container-height);
    top: 0;
  }
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .contentSlideLayout {
    height: 100vh;
    top: 0;
  }
}

.componentContentSlide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--size-164) 0;
}

.imageContainer {
  & > .imageSlideLayout {
    width: 100%;
    height: 100vh;
    top: 0;
  }
}

.componentImageSlide {
  pointer-events: none;
  opacity: 0;
  position: sticky;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-164);
  transition: opacity var(--duration-150) var(--ease-out-sine);

  &.isVisible {
    opacity: 1;
  }

  &:first-of-type {
    opacity: 1;
  }

  & > .imageLayout {
    width: 100%;
  }
}

.imageSlideContainer {
  background-color: var(--color-white);
  border-radius: var(--radius-4);
  overflow: hidden;
}

.image {
  & > .captionLayout {
    margin-top: var(--size-16);
  }
}

.componentMobileItems {
  padding: var(--size-32) 0;
}
