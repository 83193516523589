.component {
  & > .periodAndClose,
  & > .hoursLayout {
    margin-bottom: var(--size-16);
  }

  & > .scaleLayout {
    margin-bottom: var(--size-40);
  }

  & > .resultLayout {
    margin-bottom: var(--size-40);
  }

  & > .buttonLayout {
    width: 100%;
    margin-top: var(--size-48);
    margin-bottom: var(--size-8);
  }

  & > .explainText {
    margin-top: var(--size-12);
  }
}

.componentPeriodView {
  display: flex;
  align-items: center;
  gap: var(--size-32);
  position: relative;
  z-index: 0;

  & > * {
    z-index: 1;
  }

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    border-bottom: 2px solid var(--color-gray-300);
    position: absolute;
    bottom: 0;
  }
}

.periodAndClose {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > .closeLayout {
    width: 35px;
    height: 35px;
  }
}

.periodLabel {
  padding-bottom: var(--size-12);
  padding-top: var(--size-12);
  position: relative;

  --border-focus: 2px solid transparent;

  &:has(:focus-visible) {
    --border-focus: 2px solid var(--color-blue-500);
  }

  &::before {
    content: '';
    border: var(--border-focus);
    border-radius: var(--radius-4);
    position: absolute;
    inset: -2px;
  }

  &:nth-of-type(1) {
    &::after {
      display: block;
      content: '';
      border-bottom: solid 2px var(--color-blue-500);
      transform: scaleX(0);
      transition: transform var(--duration-200) ease-in-out;
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &.active {
      &::after {
        transform-origin: 0% 50%;
        transform: scaleX(1);
      }
    }
  }

  &:nth-of-type(2) {
    &::after {
      display: block;
      content: '';
      border-bottom: solid 2px var(--color-blue-500);
      transform: scaleX(0);
      transition: transform var(--duration-200) ease-in-out;
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &.active {
      &::after {
        transform-origin: 100% 50%;
        transform: scaleX(1);
      }
    }
  }

  &.active {
    color: var(--color-blue-500);
  }
}

.inputPeriod {
  appearance: none;
  border: none;
}

.componentTooltipHours {
  & > .tooltipButton {
    width: 15px;
    height: 15px;
  }
}

.componentSalaryScale {
  padding-top: var(--size-12);

  & > .scaleInput {
    width: 100%;
    margin-top: var(--size-12);
  }

  & > .valuesScales {
    margin-top: var(--size-20);
  }
}

.valuesScales {
  display: flex;
  justify-content: space-between;
}

.standardValue {
  color: var(--color-gray-400);
}

.scaleInput {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  border-radius: var(--radius-4);

  @supports not (-moz-appearance: none) {
    background: linear-gradient(
      to right,
      var(--color-blue-500) 0%,
      var(--color-blue-500) calc((var(--value, 0) - var(--min, 0)) / (var(--max, 100) - var(--min, 0)) * 100%),
      var(--color-gray-200) calc((var(--value, 0) - var(--min, 0)) / (var(--max, 100) - var(--min, 0)) * 100%)
    );
  }

  &:focus-visible {
    outline: none;
    border: 1px solid var(--color-blue-500);
  }

  &::-webkit-slider-runnable-track {
    height: var(--size-4);
    -webkit-appearance: none;
    color: var(--color-blue-500);
  }

  &::-moz-range-track {
    background: var(--color-gray-200);
    height: var(--size-4);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: calc((var(--size-4) / 2) - (var(--size-32) / 2));
    height: var(--size-32);
    width: var(--size-56);
    border-radius: var(--radius-35);
    background-color: var(--color-blue-500);
    background-image: url(/images/icons/range.svg);
    background-size: 20%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &::-moz-range-thumb {
    border: none;
    background-color: var(--color-blue-500);
    height: var(--size-32);
    width: var(--size-56);
    border-radius: var(--radius-35);
  }

  &::-moz-range-progress {
    background-color: var(--color-blue-500);
  }

  &::-ms-fill-lower {
    background-color: var(--color-blue-500);
  }
}

.componentResult {
  background-color: var(--color-blue-500);
  color: var(--color-white);
  border-radius: var(--radius-8);
}

.componentSalary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-12);

  @media (--viewport-md) {
    padding: var(--size-20);
  }

  &:first-of-type {
    border-bottom: 1px solid var(--color-blue-300);
  }
}

.value {
  font-size: var(--font-size-48);
  font-style: italic;
}

.explainText {
  font-size: var(--font-size-10);
  color: var(--color-gray-800);
}
