.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.heading {
  color: var(--accent-color);
}

.linksContainer,
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.linksContainer {
  gap: var(--size-16);
}

.tagsContainer {
  gap: var(--size-8);
}

.componentTag {
  padding: var(--size-12) var(--size-16);
  border: 1px solid var(--hairline-color);
  border-radius: var(--radius-8);

  &:hover {
    background-color: var(--hairline-color);
  }
}
