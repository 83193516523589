.component {
  z-index: 0;
  position: relative;

  & > .tooltip {
    width: 200px;
    z-index: 0;

    @media (--viewport-md) {
      width: 240px;
      left: var(--size-8);
    }
  }

  & > .tooltipButton {
    z-index: 1;
    position: relative;
  }
}

.tooltipButton {
  color: var(--color-blue-500);

  &.active {
    color: var(--color-white);
  }
}

.tooltip {
  background-color: var(--color-blue-500);
  color: var(--color-white);
  padding: var(--size-8) var(--size-8) var(--size-8) var(--size-16);
  border-radius: var(--radius-4);
  pointer-events: none;

  @media (--viewport-md) {
    padding: var(--size-8);
  }

  & > .title {
    margin-bottom: var(--size-8);
  }
}

.title {
  font-size: var(--font-size-18);
  font-style: italic;
  letter-spacing: -1px;
  line-height: var(--line-height-heading);
}
