.component {
  display: flex;
  gap: var(--size-16);
  flex-direction: column;

  @media (--viewport-md) {
    gap: var(--size-24);
    flex-direction: row;
  }

  & > .buttonLayout {
    text-transform: uppercase;
  }
}
