.heroContainer {
  position: relative;
  z-index: 0;
  padding-top: var(--size-128);
  padding-bottom: var(--size-96);

  @media (--viewport-lg) {
    padding-top: 200px;
    padding-bottom: var(--size-128);
  }

  & > .innerContainer {
    position: relative;
    z-index: -1;
  }
}

.innerContainer {
  display: flex;
  justify-content: center;
  padding: 0 var(--container-padding);

  & > .layout {
    max-width: var(--container-max-width-lg);
    width: 100%;
    margin: auto;
  }
}

.layout {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  z-index: 0;
  grid-template-areas:
    'button'
    'heading'
    'grid';

  @media (--viewport-lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: var(--size-64);
    align-items: flex-start;
    grid-template-areas:
      'button ...'
      'heading grid';
  }

  & > .heading {
    grid-area: heading;
    margin-top: var(--size-24);
    z-index: 2;

    @media (--viewport-lg) {
      max-width: 90%;
      margin-top: var(--size-164);
    }
  }

  & > .gridHomeLayout {
    grid-area: grid;
    margin-top: var(--size-96);
    z-index: 2;

    @media (--viewport-lg) {
      margin-top: 0;
    }
  }
}

.heading {
  font-size: var(--font-size-48);
  font-size: var(--font-size-48-64);
  font-family: var(--font-family-base);
  line-height: var(--line-height-heading);
  font-style: italic;
  letter-spacing: -0.04em;
  color: var(--color-blue-500);
}

.ampersand {
  color: var(--color-orange-500);
}
