.component {
  display: flex;
  flex-direction: column;
  scroll-margin-top: var(--size-16);
  background-color: var(--background-color);
  padding: var(--size-24);
  border-radius: var(--radius-4);

  @media (--viewport-lg) {
    padding: var(--size-48);
  }

  & > .form {
    margin-top: var(--size-32);
  }
}

.title {
  color: var(--accent-color);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-40);
  font-style: italic;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--size-32) var(--size-16);

  & > * {
    grid-column: 1 / span 2;
  }

  & > .span1Layout {
    @media (--viewport-lg) {
      grid-column: auto / span 1;
    }
  }
}

.recaptchaNotice {
  color: var(--color-light);
  font-size: var(--font-size-14);
  line-height: var(--line-height-text);

  & a {
    text-decoration: underline;
  }
}

.componentChecklist {
  display: grid;
  position: relative;

  &::before {
    content: '';
    display: block;
    background-color: var(--hairline-color);
    height: calc(100% - (var(--size-32) * 2));
    width: 2px;
    position: absolute;
    top: var(--size-32);
    left: 6px;
    align-self: flex-start;
  }
}

.listItem {
  display: grid;
  grid-template-columns: 20px auto;
  column-gap: var(--size-16);
  align-items: center;
  color: var(--color);
  position: relative;
  padding-bottom: var(--size-16);
  padding-top: var(--size-16);

  &:first-child::before {
    content: '';
    display: grid;
    place-self: flex-start;
    border: 1px solid var(--primary-element-background-color);
    background-color: var(--primary-element-background-color);
    border-radius: 100%;
    height: 15px !important;
    width: 15px !important;
  }

  &:not(:first-child)::before {
    content: '';
    display: block;
    border: 1px solid var(--hairline-color);
    background-color: var(--background-color);
    border-radius: 100%;
    height: 15px !important;
    width: 15px !important;
  }

  &:first-child::after {
    content: '';
    background-color: var(--color);
    height: 40%;
    width: 2px;
    position: absolute;
    left: 6px;
    top: 30px;
  }
}

.componentThankYouCard {
  background-color: var(--background-color);
  color: var(--color);
  border-radius: var(--radius-12);
  padding: var(--size-24);
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-48);
  }
}

.thankYouText {
  line-height: var(--line-height-text);
}
