.component {
  & > .nameLayout {
    margin-top: var(--size-16);
  }
}

.componentBlockquote {
  --progress: 0;
  --pseudo-quote-color: var(--accent-color);

  line-height: var(--line-height-heading);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-64);
  font-style: italic;

  & > .quoteHighlightElement {
    margin: -1rem;
  }

  &::before {
    content: '"';
    margin-left: -0.3em;
    color: var(--pseudo-quote-color);
  }
}

.quoteHighlightElement {
  --percentage: calc(var(--progress) * 1%);
  --from: var(--accent-color) var(--percentage);
  --to: var(--color-gray-200) min(100%, calc(var(--percentage) + 1%));

  padding: 1rem;
  background-image: linear-gradient(90deg, var(--from), var(--to));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.componentName {
  font-size: var(--font-size-16);
  color: var(--accent-color);
  font-style: italic;
}

.nonSelectableQuote {
  user-select: none;
}
