.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  color: var(--color-blue-500);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  & > .titleLayout {
    @media (--viewport-md) {
      grid-column: 1 / 6;
    }
  }

  & > .introductionLayout {
    @media (--viewport-md) {
      grid-column: 7 / 12;
    }
  }
}
