.page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: var(--menu-padding-sm);

  & > .introLayout {
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-64);
    }
  }
}

.intro {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'text';
  gap: var(--size-32);

  @media (--viewport-md) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'title title text text text';
    gap: var(--size-48);
  }

  & > .introTitle {
    grid-area: title;
  }

  & > .introText {
    grid-area: text;
  }
}

.introTitle {
  color: var(--color-blue-500);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-48);
  line-height: var(--line-height-heading);
  font-style: italic;
  text-wrap: balance;
}
