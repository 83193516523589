.component {
  background-color: var(--color-gray-100);
  padding: var(--size-24) 0;
  letter-spacing: var(--letter-spacing-text);
  line-height: var(--line-height-text);
  border-radius: 0 0 var(--radius-4) var(--radius-4);
}

.inner {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: var(--size-24);

  @media (--viewport-md) {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    grid-column-gap: var(--size-48);
  }

  @media (--viewport-xl) {
    grid-template-columns: repeat(5, 1fr);
  }

  & > .employeeLayout {
    grid-column: span 2;
  }

  & > .readingTime {
    grid-column: span 1;
  }

  & > .socialLayout {
    grid-column: -2;
    justify-self: end;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    gap: var(--size-32);
  }
}

.readingTime {
  display: flex;
  flex-direction: column;
}

.minutes {
  font-weight: var(--font-weight-600);
}
