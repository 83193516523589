.component {
  & > .containerLayout {
    margin-top: var(--size-32);

    @media (--viewport-lg) {
      margin-top: var(--size-48);
    }
  }
}

.innerContainer {
  display: grid;
  line-height: var(--line-height-text);
  grid-template-columns: 1fr;
  grid-template-areas:
    'filters'
    'main';
  grid-gap: var(--size-32);
  padding-bottom: var(--size-64);
  z-index: 0;
  position: relative;

  @media (--viewport-lg) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'filters main';
    grid-gap: var(--size-16);
    grid-template-rows: 1fr;
  }

  & > .filters {
    grid-area: filters;

    @media (--viewport-lg) {
      max-width: 426px;
    }
  }

  & > .mobileFilters {
    width: 100%;

    &.isSticky {
      top: var(--size-16);
      height: var(--size-64);
      width: max-content;
      z-index: 1;
    }
  }

  & > .main {
    grid-area: main;
  }
}

.main {
  & > .counter {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin: var(--size-16) 0;
    }
  }
}

.counter {
  font-size: var(--font-size-18);
}

.highlight {
  color: var(--accent-color);
}

.jobFilters {
  background-color: var(--background-color);
  border-radius: var(--radius-4);
}

.mobileFilters {
  transition: width var(--duration-200) var(--ease-in-out);
  display: block;

  @media (--viewport-md) {
    display: none;
  }

  &.isSticky {
    position: sticky;
    background-color: var(--background-color);
    box-shadow: var(--shadow-lg);
    border-radius: var(--radius-8);
    overflow: hidden;
  }

  & > * {
    height: 100%;
  }
}

.innerMobileFilters {
  &.isSticky {
    padding: var(--size-4);
  }

  & > * {
    height: 100%;
  }
}

.filters {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.filterContent {
  & > .filterHeading {
    margin: var(--size-16) 0;
  }
}

.filterSearch {
  & > .searchFieldLayout {
    margin-bottom: var(--size-32);
  }
}

.filterHeading {
  font-size: var(--font-size-18);
}
