.component {
  position: relative;
  z-index: 0;

  & > .inner {
    max-width: 1200px;
    width: 100%;
    margin: auto;

    @media (--viewport-md) {
      height: 100vh;
    }
  }

  & > .backgroundImage {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.inner {
  display: grid;
  grid-template-columns: var(--size-24) 1fr var(--size-24);
  row-gap: var(--size-32);
  grid-template-rows: auto auto;
  padding: var(--size-32) 0 var(--size-80);
  grid-template-areas:
    '. tiles   .'
    '. content .';

  &.reversed {
    @media (--viewport-md) {
      grid-template-areas: 'content tiles';
      grid-template-columns: 4.4fr 5.6fr;
      grid-template-rows: auto;
    }

    & > .content {
      @media (--viewport-md) {
        margin-left: var(--size-24);
      }

      @media (--viewport-xl) {
        margin-left: 0;
      }
    }
  }

  @media (--viewport-md) {
    grid-template-areas: 'tiles content';
    grid-template-columns: 5.6fr 4.4fr;
    grid-template-rows: auto;
    gap: var(--size-16);
    padding: 0;
  }

  & > .content {
    align-self: self-start;
    grid-area: content;

    @media (--viewport-md) {
      margin-right: var(--size-24);
      align-self: center;
    }

    @media (--viewport-xl) {
      margin-right: 0;
    }
  }

  & > .tiles {
    align-self: self-end;
    grid-area: tiles;

    @media (--viewport-md) {
      margin-left: var(--size-80);
      margin-right: var(--size-80);
      align-self: center;
    }
  }
}

.tiles {
  display: grid;
  gap: var(--size-16);

  @media (--viewport-md) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: var(--size-16);
    grid-row-gap: var(--size-16);
    grid-template-areas:
      "tile1 tile1 tile1 ... ..."
      "tile1 tile1 tile1 tile2 tile2"
      "tile1 tile1 tile1 tile2 tile2"
      "... tile3 tile3 ... ..."
      "... tile3 tile3 ... ...";
  }

  &.reversed {
    @media (--viewport-md) {
      grid-template-areas:
        "... tile1 tile1 ... ..."
        "... tile1 tile1 ... ..."
        "tile2 tile2 tile2 tile3 tile3"
        "tile2 tile2 tile2 tile3 tile3"
        "tile2 tile2 tile2 ... ...";
    }
  }

  & > :first-child {
    @media (--viewport-md) {
      grid-area: tile1;
    }
  }

  & > :nth-child(2) {
    @media (--viewport-md) {
      grid-area: tile2;
    }
  }

  & > :nth-child(3) {
    @media (--viewport-md) {
      grid-area: tile3;
    }
  }
}

.content {
  & > .titleLayout {
    margin-bottom: var(--size-32);
  }
}

.backgroundImage {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  & > * {
    height: 100%;
    width: 100%;
  }
}
