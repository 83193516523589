.componentLabel {
  border-bottom: 1px solid var(--hairline-color);
}

.componentTile {
  background-color: var(--secondary-accent-color);
  color: var(--primary-element-color);
  border-radius: var(--radius-12);
  font-weight: var(--font-weight-400);
  font-style: italic;
  padding: var(--size-24);
  font-size: var(--font-size-24-28);

  &:hover {
    background-color: var(--secondary-background-color);
    color: var(--secondary-element-color);
  }

  &:active,
  &:focus {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: 2px;
  }

  @media (--viewport-md) {
    padding: var(--size-8);
    aspect-ratio: 5 / 7;
  }
}
