.component {
  display: none;
  flex-direction: column;
  gap: var(--size-12);
  transform: translateY(-50%);

  @media (--viewport-md) {
    display: flex;
  }
}

.componentBullet {
  position: relative;

  & > .button {
    width: var(--size-8);
    height: var(--size-8);
  }
}

.button {
  background-color: var(--secondary-hairline-color);
  border-radius: var(--radius-circular);
  position: relative;
  display: block;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border: 1px solid transparent;
    border-radius: var(--radius-circular);
    transition: border-color var(--duration-200) var(--ease-in-out), background-color var(--duration-200) var(--ease-in-out);
  }

  &:hover,
  &:active {
    background-color: var(--secondary-accent-color);

    &::before {
      border-color: var(--secondary-accent-color);
    }
  }

  &.active {
    background-color: var(--secondary-accent-color);

    &::before {
      border-color: var(--secondary-accent-color);
    }
  }
}

.tooltip {
  background-color: var(--color-blue-500);
  color: var(--color-blue-500);
  padding: var(--size-8) var(--size-12);
  border-radius: var(--radius-4);
  position: relative;

  & > .iconLayout {
    position: absolute;
    right: -7px;
    top: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.label {
  color: var(--color-white);
  white-space: nowrap;
}
