.component_root {
  padding: var(--size-96) 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  max-height: 800px;


  & > .loaderLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}
