.component {
  position: relative;
  background-color: var(--background-color);
  color: var(--color);
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-40);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-white--20);
  }

  & > .image {
    width: 90px;
    height: 90px;
    align-self: flex-start;

    @media (--viewport-md) {
      align-self: unset;
    }
  }
}

.image {
  border-radius: var(--radius-4);
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-12);

  & > .role {
    margin-bottom: var(--size-24);
  }
}

.role {
  font-size: var(--font-size-18-22);
  font-style: italic;
}

.links {
  display: flex;
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-8);
  }
}

.componentSocialContact {
  display: flex;
  align-items: center;
  justify-content: center;

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.icon {
  border: 1px solid currentColor;
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;

  &.filled {
    background-color: var(--background-color);
    color: var(--color);
  }
}
