.componentShareLinks {
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .shareLinkLayout {
    width: var(--size-40);
    height: var(--size-40);
  }
}

.componentShareLink {
  border: 1px solid var(--hairline-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color);

  &:focus,
  &:hover {
    background-color: var(--color);
    color: var(--primary-element-color);
  }
}
