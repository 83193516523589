.component {
  scroll-snap-type: y mandatory;
  z-index: 0;
  position: relative;

  & > .navigationLayout {
    position: fixed;
    z-index: 1;
    top: 50%;
    right: var(--size-16);
    margin: 0;
  }
}

.scrollSpy {
  scroll-snap-align: start;
}

.componentSection {
  position: sticky;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  background-color: var(--background-color);
  color: var(--accent-color);
  border-radius: var(--radius-12) var(--radius-12) 0 0;
  overflow: hidden;

  & > * {
    min-height: 100vh;
  }
}
