.component {
  position: relative;
  display: flex;
  align-items: center;

  & > .inputSearch {
    width: 100%;
  }

  & > .searchIconLayout {
    position: absolute;
    left: var(--size-24);
    width: var(--size-24);
  }

  & > .searchFieldLayout {
    margin: var(--size-48) 0 var(--size-32);
  }

  & > .iconButton {
    position: absolute;
    top: 50%;
    right: var(--size-24);
    width: var(--size-32);
    height: var(--size-32);
  }
}

.inputSearch {
  padding: var(--size-24) var(--size-80) var(--size-24) var(--size-64);
  border-radius: var(--radius-4);
  border: 1px solid var(--color-light, var(--shade-color));
  background-color: var(--shade-color);
  outline: 1px solid transparent;
  transition: outline 150ms ease;

  &:placeholder-shown {
    @media not (--viewport-xs) {
      padding: var(--size-24) var(--size-24) var(--size-24) var(--size-64);
    }
  }

  &::placeholder {
    user-select: none;
    color: var(--color-light);
  }

  &:focus {
    border-color: var(--accent-color);
    outline: 1px solid var(--accent-color);
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.iconButton {
  transform: translateY(-50%);
  padding: var(--size-8);
  color: var(--color);
  fill: var(--color);
  border: 1px solid currentColor;
  border-radius: var(--radius-circular);

  &:hover {
    background-color: var(--tertiary-element-background-color-hover);
    color: var(--tertiary-element-color-hover);
    fill: var(--tertiary-element-color-hover);
    border-color: var(--tertiary-element-background-color-hover);
  }
}
