.componentBase {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  &.componentOneColumn {
    flex-direction: column;

    & > * {
      width: 100%;
    }
  }

  &.componentTwoColumns {
    @media (--viewport-md) {
      flex-direction: row;
    }
  }

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }
}

.componentColumnContent {
  & > .heading {
    margin-bottom: var(--size-24);
  }
}

.faqs {
  & > :not(:last-child) {
    margin-bottom: var(--size-16);
  }
}

.containerAnswer {
  overflow: hidden;
  transition: height var(--duration-200) var(--ease-in-out);

  & > * {
    margin-top: var(--size-16);
  }
}

.componentFaq {
  background-color: var(--shade-color);
  padding: var(--size-16);
  border-radius: var(--radius-12);

  @media (--viewport-md) {
    padding: var(--size-24);
  }
}

.heading {
  color: var(--accent-color);
}

.header {
  display: flex;
  justify-content: space-between;
  gap: var(--size-16);
  position: relative;
  align-items: center;
  z-index: 0;

  & > .question {
    position: static;
    z-index: 1;
  }

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
    flex-shrink: 0;
    z-index: 0;
  }
}

.question {
  font-size: var(--font-size-20);
  font-style: italic;
  position: relative;

  & > .button {
    position: static;
  }

  &.active {
    color: var(--accent-color);
  }
}

.button {
  text-align: left;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.icon {
  border: 1px solid var(--color);
  border-radius: var(--radius-circular);
  padding: var(--size-8);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  transform-origin: center;
  transition: transform var(--duration-200) var(--ease-in-out);

  &.fill {
    background-color: var(--color);
    color: var(--shade-color);
  }

  &.rotate {
    transform: rotate(0deg);
  }
}

.chevron {
  transform: translateY(-2px);
}
