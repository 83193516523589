.component {
  font-size: var(--font-size-18);
}

.list {
  list-style-type: disc;
  list-style-position: outside;
  padding: 0 var(--size-24);
  line-height: var(--line-height-text);
}

.componentContentBase {
  line-height: var(--line-height-text);

  & > .listLayout {
    margin: 1em 0;
  }
}
