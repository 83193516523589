.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.imageContainer {
  border-radius: var(--radius-4);
  overflow: hidden;
}
