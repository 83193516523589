.component {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-40);
  font-style: italic;
  line-height: var(--line-height-heading);
  color: var(--accent-color);
}

/* stylelint-disable kaliber/selector-policy */
.component > strong {
  font-weight: inherit;
}
/* stylelint-enable kaliber/selector-policy */
