.componentButtonBlock {
  --overlay-opacity: 0;

  position: relative;
  z-index: 0;

  &:not(.block) {
    &:hover,
    &:focus {
      --overlay-opacity: 0.1;
      --arrow-opacity: 1;
    }
  }

  &:focus-within {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: 2px;
    border-radius: var(--radius-8);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: var(--overlay-opacity);
    background-color: var(--color-black);
    transition: opacity var(--duration-200);
  }
}
