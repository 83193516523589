.component {
  background-color: var(--shade-color);
  mask-image: url('/images/mobile-swirl.svg');
  mask-size: contain;
  mask-position: top left;
  mask-repeat: no-repeat;

  @media (--viewport-md) {
    mask-image: url('/images/desktop-swirl.svg');
  }
}
