.componentBase {
  --padding: var(--size-16);
  --icon-size: var(--size-16);
  --circle-size: var(--size-40);

  --icon-color: var(--color-white);
  --icon-background-color: var(--color-black--20);
  --icon-background-hover-color: var(--color-gray-900);

  display: flex;
  align-items: flex-end;
  pointer-events: auto;
  padding: var(--padding);
  position: relative;

  & > .buttonContainer {
    position: static;
  }
}

.componentSmall,
.componentPlayButtonSmall {
  --padding: var(--size-16);
}

.componentLarge {
  --padding: var(--size-16);

  @media (--viewport-md) {
    --padding: var(--size-32);
  }
}

.smallButtonContainer {
  --icon-size: var(--size-16);
  --circle-size: var(--size-40);

  & > .headingButton {
    @media (--viewport-md) {
      max-width: 80%;
    }
  }
}

.largeButtonContainer {
  --icon-size: var(--size-16);
  --circle-size: var(--size-40);

  @media (--viewport-md) {
    --icon-size: var(--size-24);
    --circle-size: var(--size-64);
  }

  & > .headingButton {
    @media (--viewport-md) {
      max-width: 50%;
    }
  }
}

.componentWavemakers {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  gap: var(--size-40);

  & > .buttonContainer {
    grid-row: 2 / span 1;
    align-self: start;
  }
}

.wavemakersButtonContainer {
  --icon-color: var(--color-gray-800);
  --icon-background-color: var(--color-white);
  --icon-background-hover-color: var(--color-white--80);

  --icon-size: var(--size-24);
  --circle-size: var(--size-64);

  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .headingButton {
    @media (--viewport-md) {
      max-width: 100%;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
  align-items: center;
  color: var(--color-white);
  position: relative;

  @media (--viewport-md) {
    gap: var(--size-16);
  }

  & > .button {
    position: static;
    width: var(--circle-size);
    height: var(--circle-size);
  }
}

.button {
  color: var(--icon-color);
  background: var(--icon-background-color);
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background var(--duration-200);

  &:hover,
  &:focus {
    background: var(--icon-background-hover-color);
  }

  &:focus-visible,
  &:focus-within {
    outline: 2px solid var(--secondary-element-color);
    outline-offset: 2px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > * {
    width: var(--icon-size);
    height: var(--icon-size);
  }
}

.headingButton {
  font-style: italic;
  font-size: var(--font-size-18-22);
}
