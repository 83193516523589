.component {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  & > .iframeLayout {
    height: 90%;
    place-self: center;

    @media (--viewport-md) {
      height: 100%;
    }
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "content"
      "iframe";
    gap: var(--size-32);

    @media (--viewport-md) {
      gap: var(--size-64);
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "content iframe";
    }

    & > .contentLayout {
      grid-area: content;
    }

    & > .iframeLayout {
      width: 100%;
      height: 560px;
      grid-area: iframe;
      place-self: center;

      @media (--viewport-md) {
        height: auto;
      }
    }
  }
}

.title {
  color: var(--color-blue-500);
}

.componentContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.componentIframe {
  border-radius: var(--radius-12);
  border: none;

  @media (--viewport-md) {
    aspect-ratio: 3 / 5;
  }
}
