.component {
  color: var(--color-blue-500);

  & > .headingLayout {
    margin-bottom: var(--size-8);
  }
}

.componentSearchInput {
  position: relative;
  color: var(--color-gray-900);

  & > .input {
    width: 100%;
    height: var(--size-48);
    margin-bottom: var(--size-12);
  }

  & > .icon {
    position: absolute;
    left: var(--size-12);
    top: var(--size-12);
  }
}

.icon {
  color: var(--color-blue-500);
  height: var(--size-24) !important;
  width: var(--size-24) !important;
}

.input {
  background-color: var(--color-white);
  border-radius: var(--radius-12);
  padding-left: var(--size-48);
  box-shadow: var(--shadow-sm);
  border: none;
}

.searchResultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: var(--size-8);
  gap: var(--size-8);
}
