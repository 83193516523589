.component {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  padding: var(--size-8);
  border-radius: var(--radius-8);

  & > .bulletLayout,
  & > .previousLayout,
  & > .nextLayout {
    width: var(--size-32);
    height: var(--size-32);

    @media not (--viewport-xs) {
      width: var(--size-20);
    }
  }
}

.bulletSeparator {
  padding: 0 var(--size-8);
}

.componentBullet {
  background-color: var(--background-color);
  color: var(--color);
  border-radius: var(--radius-4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.componentArrowButton {
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    opacity: 0.5;
  }
}
