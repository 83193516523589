.componentRewardCalculatorPortal {
  pointer-events: auto;
  overflow: hidden;
  padding: var(--size-8);
  background-color: var(--color-black--70);

  @media (--viewport-md) {
    padding: var(--size-32);
  }

  & > .rewardCalculatorWrapper {
    height: 100%;
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
  }
}

.rewardCalculatorContainer {
  background-color: var(--color-white);
  border-radius: var(--radius-12);
  padding: var(--size-32);

  @media (--viewport-md) {
    padding: var(--size-40);
  }
}
