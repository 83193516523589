.component {
  position: relative;
  z-index: 0;

  & > .title {
    position: static;
    margin-bottom: var(--size-16);
  }

  & > .titleSm {
    margin-bottom: var(--size-8);
  }

  & > .info {
    z-index: 1;
    position: static;
  }
}

.title {
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-32);
  line-height: var(--line-height-heading);
  color: var(--accent-color);
  font-style: italic;
  position: relative;

  &.titleSm {
    font-size: var(--font-size-22);
  }
}

.info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
  }

  & > .metadata {
    order: -1;
    margin-bottom: var(--size-8);

    @media (--viewport-md) {
      order: 0;
      margin-bottom: 0;
    }
  }
}

.match {
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .button {
    width: var(--size-24);
    height: var(--size-24);
  }

  &::after {
    content: '';
    width: 1px;
    height: 32px;
    background-color: var(--border-color);
  }
}

.button {
  background-color: var(--background-color);
  color: var(--color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metadata {
  display: flex;
  gap: var(--size-8);
  flex-wrap: wrap;

  & > .dateLayout {
    align-self: center;
  }
}

.componentBranches {
  display: flex;
  flex-wrap: wrap;
}

.branch {
  font-style: italic;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: "•";
      color: var(--color);
      margin: 0 var(--size-8);
    }
  }
}
