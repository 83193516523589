.component {
  overflow: hidden;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  @media (--viewport-xxl) {
    display: grid;
    gap: var(--size-16);
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: 'text text text text text subvakgebieden subvakgebieden subvakgebieden subvakgebieden subvakgebieden subvakgebieden subvakgebieden';
  }

  & > .content {
    @media (--viewport-xxl) {
      grid-area: text;
    }
  }

  & > .subvakgebieden {
    @media (--viewport-xxl) {
      grid-area: subvakgebieden;
    }
  }
}

.content {
  color: var(--color);

  @media (--viewport-xxl) {
    padding: 0 var(--size-48);
  }

  & > .headingLayout {
    margin-bottom: var(--size-24);
  }
}

.componentGrid {
  display: none;

  @media (--viewport-xxl) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--size-16);
  }
}

.componentSlider {
  overflow: visible !important; /* Keen Slider override */
  display: block;

  @media (--viewport-xxl) {
    display: none;
  }

  & > .slide {
    margin: 1px;
  }
}

.slide {
  aspect-ratio: 3 / 4;
}

.componentCard {
  & > * {
    height: 100%;
  }
}

.container {
  background-color: var(--background-color);
  color: var(--accent-color);
  border-radius: var(--radius-12);
  padding: var(--size-24);
  display: flex;
  flex-direction: column;
  position: relative;
  border: 2px solid var(--accent-color);
  justify-content: space-between;
  aspect-ratio: 3 / 4;

  &:focus-within {
    border-color: var(--secondary-background-color);
  }

  & > .bottom {
    position: static;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
  }

  & > .link {
    position: static;
    z-index: 1;
  }
}

.headerCard {
  & > .title {
    margin-bottom: var(--size-16);
  }
}

.title {
  font-size: var(--font-size-32);
  font-style: italic;

  @media (--viewport-md) {
    font-size: var(--font-size-22);
  }
}

.intro {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: var(--color);
}

.icon {
  border: 1px solid var(--color);
  color: var(--color);
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.link {
  color: var(--color);
  font-style: italic;
  opacity: 0;
  position: relative;
  transition: opacity var(--duration-200);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  &.visible {
    opacity: 1;
  }
}
