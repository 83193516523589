.component {
  padding-top: 0;

  @media (--viewport-lg) {
    padding-top: var(--size-128);
  }

  & > .content {
    margin-bottom: var(--size-80);

    @media (--viewport-lg) {
      margin-bottom: var(--size-128);
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content;
  gap: var(--size-16);
  grid-template-areas:
    'text'
    'label';

  @media (--viewport-lg) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      '... ... tile tile tile ... text text text text text text'
      '... ... tile tile tile ... label label label label label label';
  }

  & > :nth-child(1) {
    grid-area: tile;
    display: none;
    margin-left: calc(-1 * var(--size-32));
    margin-right: var(--size-16);

    @media (--viewport-lg) {
      margin-top: calc(-1 * 112px);
      display: block;
    }
  }

  & > .label {
    grid-area: label;

    @media (--viewport-lg) {
      margin-top: var(--size-12);
    }
  }

  & > .textLayout {
    grid-area: text;
  }
}

.label {
  font-size: var(--font-size-22);
  color: var(--accent-color);
  font-style: italic;
}
