.component,
.componentJobDetail,
.componentWithDate {
  display: flex;
  flex-wrap: wrap;
}

.componentLabel,
.componentLabelWithSalaryModal,
.date {
  font-size: var(--font-size-14);
  color: var(--color);
  line-height: var(--line-height-text);
  display: flex;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: "•";
      color: var(--hairline-color);
      margin: 0 var(--size-8);
    }
  }

  & > .iconLayout {
    margin-right: var(--size-8);
  }
}
