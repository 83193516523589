._root {
  @media not (--viewport-lg) {
    margin: 0 5vw;
  }
}

.component {
  display: grid;
  position: relative;
  z-index: 0;

  & > * {
    overflow: hidden;
  }

  & > .imageLeft {
    width: 100px;
    z-index: 1;
    position: absolute;

    @media (--viewport-md) {
      width: 130px;
    }
  }

  & > .imageRight {
    width: 100px;
    place-self: flex-end;
    z-index: -1;
    position: absolute;

    @media (--viewport-md) {
      width: 180px;
    }
  }
}

.componentVideo {
  border-radius: var(--radius-8);
}

.imageLeft {
  border-radius: var(--radius-12);
}

.imageRight {
  border-radius: var(--radius-12);
}

.content {
  position: relative;

  & > .playLayout {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.poster {
  border-radius: var(--radius-8);
  overflow: hidden;
}

.componentVideoPortal {
  pointer-events: auto;
  overflow: hidden;
  padding: var(--size-8);
  background-color: var(--color-black--70);

  @media (--viewport-md) {
    padding: var(--size-32);
  }

  & > .videoPortalWrapper {
    height: 100%;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
  }
}

.videoPortalWrapper {
  position: relative;

  & > .iframeLayout {
    height: 100%;
    width: 100%;
  }

  & > .closeLayout {
    position: absolute;
    right: -40px;
    top: 0;
    width: var(--size-36);
    height: var(--size-36);

    @media (--viewport-md) {
      right: -60px;
      top: -20px;
    }
  }
}

.componentCloseButton {
  color: var(--color-white);
}
