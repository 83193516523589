.component {
  position: relative;
  overflow: hidden;

  & > .loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & > .errorLayout {
    width: 100%;
    height: 100%;
  }

  & > .contentLayout {
    width: 100%;
    height: 100%;
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .loaderLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}

.componentContent {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "heading"
    "slider";
  gap: var(--size-16);
  color: var(--color-blue-500);
  padding: var(--size-24) 0;
  text-align: center;
  overflow: hidden;

  & > .headingLayout {
    grid-area: heading;
  }

  & > .sliderLayout {
    grid-area: slider;
  }
}

.componentSlider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.sliderContainer {
  z-index: 0;
  position: relative;
  padding: 0 var(--size-32);

  @media (--viewport-md) {
    padding: 0 var(--size-48);
  }

  & > * {
    width: 100%;
    margin: 0 auto;
  }

  & > .slider {
    overflow: visible;
    min-height: 350px;
  }

  & > .cursorTooltipLayout {
    z-index: 1;
    position: absolute;
    width: max-content;
  }
}

.componentCursorTooltip {
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .iconContainer {
    width: var(--size-48);
    height: var(--size-48);
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-12);
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-black--20);
}

.tooltip {
  opacity: 0;
  position: relative;
  padding: var(--size-8) var(--size-12);
  color: var(--color-white);
  background-color: var(--color-blue-500);
  border-radius: var(--radius-4);
  box-shadow: var(--shadow-sm);
  transform: scale(0.7);
  transition:
    opacity   var(--duration-200) var(--ease),
    transform var(--duration-200) var(--ease);

  &.isIdle {
    opacity: 1;
    transform: scale(1);
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 100%;
    top: 50%;
    margin-top: calc(-1 * var(--size-4));
    border-top: var(--size-4) solid transparent;
    border-bottom: var(--size-4) solid transparent;
    border-right: var(--size-4) solid var(--color-blue-500);
  }
}

.slider {
  display: flex;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.sliderDisabled {
    gap: var(--size-8);
    cursor: auto;

    @media (--viewport-md) {
      gap: var(--size-16);
    }

    @media (--viewport-lg) {
      gap: var(--size-32);
    }

    & > * {
      flex: 1;
    }
  }

  & > .slideContainer {
    height: auto;
  }
}

.slideContainer {
  aspect-ratio: 5 / 7;
  display: flex;
  align-items: flex-end;

  & > .cardLayout {
    height: 100%;

    @media (--viewport-lg) {
      height: 90%;
    }
  }

  &.isCurrentSlide {
    & > .cardLayout {
      @media (--viewport-lg) {
        height: 100%;
      }
    }
  }
}

.componentCard {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--size-12);
  color: var(--color-white);
  background-color: var(--color-blue-500);
  border-radius: var(--radius-12);
  text-align: left;
  transition: height var(--duration-450) var(--ease);
  overflow: hidden;

  & > .textContainer {
    height: 100%;
  }

  & > .imageContainer {
    z-index: -2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.titleContainer {
  transform: scale(0.7);
  transform-origin: top left;
  transition: transform var(--duration-200) var(--ease);

  &.isCurrentSlide {
    transform: scale(1);
  }
}

.title {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-64);
  line-height: var(--line-height-heading);
  letter-spacing: -2px;
  font-style: italic;
}

.titleContainer,
.description {
  padding: 0 var(--size-16);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-16) 0 0;

  & > .description {
    flex-grow: 1;
  }

  & > .buttonContainer {
    flex-shrink: 0;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.description {
  opacity: 0;
  overflow-y: auto;
  font-size: var(--font-size-16);
  line-height: var(--line-height-text-sm);
  scrollbar-width: none;
  transform: translateY(var(--size-12));
  transition:
    opacity   var(--duration-150) var(--ease),
    transform var(--duration-450) var(--ease);

  &.isCurrentSlide {
    opacity: 1;
    transform: translateY(0);
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.imageContainer {
  opacity: 1;
  z-index: 0;
  position: relative;
  transition: opacity var(--duration-450) var(--ease);

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 25%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  }

  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 15%;
    bottom: 0;
    left: 0;
    background: var(--color-black-gradient);
  }

  &.isCurrentSlide {
    opacity: 0;
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: cover;
}
