.headerContent {
  padding-top: var(--menu-padding);
  position: relative;
  z-index: 0;

  & > .containerLayout {
    @media (--viewport-md) {
      margin-top: var(--size-64);
    }
  }

  & > .swirlLayout {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.componentIntro {
  display: flex;
  gap: var(--size-48);
  flex-direction: column;
}

.introHeader {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
  color: var(--accent-color);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      '... ... breadcrumbs  breadcrumbs ...'
      '... ... title        title       title';
    gap: var(--size-12) var(--size-48);
  }

  & > .breadcrumbsLayout {
    @media (--viewport-md) {
      grid-area: breadcrumbs;
    }
  }

  & > .headingLayout {
    @media (--viewport-md) {
      grid-area: title;
    }
  }
}

.componentBreadcrumbs {
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.componentBreadcrumbLink {
  pointer-events: auto;
  color: var(--accent-color);

  &:hover {
    color: var(--color);
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'metadata metadata content content content';
    grid-template-rows: 1fr;
  }

  & > .introText {
    @media (--viewport-md) {
      grid-area: content;
    }
  }

  & > .metaData {
    @media (--viewport-md) {
      grid-area: metadata;
      width: fit-content;
      justify-self: flex-end;
    }
  }
}

.componentMetaDataCard {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  padding: var(--size-24);
  border-radius: var(--radius-4);
  background-color: var(--background-color);
}

.metaDataWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-32);
  }
}

.readingTime {
  font-weight: bold;
}

.employees {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  line-height: var(--line-height-text-sm);

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--hairline-color);
  }
}

.linkToOverviewContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  color: var(--color-blue-700);

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--hairline-color);
  }
}
