.component {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--size-32) var(--size-8);
  background-color: var(--background-color);
  color: var(--color);
  border-radius: var(--radius-4);
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-48);
  }

  & > .span2Layout {
    grid-column: 1 / span 2;
  }
}

.componentItem {
  & > .title {
    margin-bottom: var(--size-8);
  }
}

.title {
  font-size: var(--font-size-18);
  color: var(--accent-color);
  font-style: italic;
}

.value {
  font-size: var(--font-size-18);
  color: var(--color);
  font-style: italic;
}
