.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  & > .leftColumnLayout {
    @media (--viewport-md) {
      grid-column: 1 / span 6;
    }
  }

  & > .rightColumnLayout {
    @media (--viewport-md) {
      grid-column: 7 / span 5;
    }
  }
}

.componentRight {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  & > * {
    @media (--viewport-md) {
      grid-column: 7 / span 5;
    }
  }
}

.componentArticle {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  @media (--viewport-md) {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(5, 1fr);
    gap: var(--size-48);
  }

  & > * {
    @media (--viewport-md) {
      grid-column: 3 / span 3;
    }
  }
}
