.component {
  z-index: 0;
  position: relative;

  & > .heroWrapper {
    z-index: 1;
    width: 100%;
    height: 100lvh;
    top: 0;
  }
}

.heroWrapper {
  position: sticky;

  & > .heroContainer {
    width: 100%;
    height: 100%;
  }
}

.headingContainer {
  & > .heading,
  & > .subheading {
    max-width: 18ch;
  }

  & > .subheading {
    margin-top: var(--size-16);
  }
}

.heading,
.subheading {
  color: var(--color-white);
  text-align: center;
  font-style: italic;
  font-size: var(--font-size-40-45);
  line-height: var(--line-height-heading);
  text-wrap: balance;

  @media (--viewport-md) {
    font-size: var(--font-size-48);
  }
}

.heroContainer {
  z-index: 0;
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;

  & > * {
    grid-area: 1 / -1;
  }

  & > .videoLayout {
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  & > .scrollNudgeLayout {
    z-index: 1;
    position: absolute;
    width: var(--size-64);
    height: var(--size-64);
    bottom: calc(env(safe-area-inset-bottom, 0px) + 20svh);
    left: 50%;
    margin-left: calc(-1 * var(--size-32));
  }
}

.componentScrollNudge {
  opacity: 0;
  display: grid;
  place-items: center;
  padding: var(--size-16);
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-black--20);
  backdrop-filter: blur(10px);
  border: 1px solid var(--color-white--10);
  transform: translateY(var(--y)) rotate(var(--rotate));
  animation:
    nudgeOpacity  var(--duration-600)   ease        forwards var(--duration-600),
    nudgeSlideIn  var(--duration-600)   ease        forwards var(--duration-600),
    nudgeUpDown   var(--duration-1200)  ease-in-out forwards 2s infinite;

  &:not(.isActive) {
    display: none;
  }

  & > .iconLayout {
    grid-area: 1 / -1;
  }
}

/**
  MARK: @property
*/

@property --rotate {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@property --y {
  syntax: '<percentage>';
  initial-value: 100%;
  inherits: false;
}

/**
  MARK: @keyframes
*/

@keyframes nudgeOpacity {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes nudgeSlideIn {
  from { --y: 100%; }
  to { --y: 0%; }
}

@keyframes nudgeUpDown {
  0% { --y: 0%; }
  20% { --y: 5%; }
  35% { --y: 0%; }
}
