.component {
  background-color: var(--background-color);
  color: var(--color);
  padding: var(--menu-padding-sm) 0 var(--size-32);

  @media (--viewport-md) {
    padding: var(--menu-padding-sm) 0 var(--size-64);
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title'
    'photos';
  gap: var(--size-24);

  @media (--viewport-md) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'title title title photos photos';
    grid-gap: var(--size-48);
    grid-template-rows: 1fr;
  }

  & > .title {
    grid-area: title;
  }

  & > .photos {
    grid-area: photos;
  }
}

.title {
  display: flex;
  align-items: center;
}

.photos {
  position: relative;
  padding: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  & > [data-index='0'] {
    z-index: 1;
  }

  & > [data-index='1'] {
    width: 23%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }

  & > [data-index='2'] {
    width: 37%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  & > [data-index='3'] {
    width: 23%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.image {
  display: inline-block;
  border-radius: var(--radius-12);
  overflow: hidden;
}
