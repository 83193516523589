.hoursHeader {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .tooltipLayout {
    z-index: 1;
  }
}

.hours {
  padding-top: var(--size-16);

  & > .buttons {
    margin-top: var(--size-12);
  }
}

.hoursInput {
  appearance: none;
  border: none;
}

.hoursLabel {
  font-style: italic;
  padding: var(--size-12) 0;
  border: 1px solid var(--color-gray-200);
  border-radius: var(--radius-35);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > .hoursInput {
    width: 0;
  }

  --border-focus: 2px solid transparent;

  &:has(:focus-visible) {
    --border-focus: 2px solid var(--color-blue-500);
  }

  &::after {
    content: '';
    position: absolute;
    border: var(--border-focus);
    border-radius: var(--radius-35);
    inset: -3px;
  }

  &.visibleActive {
    border: 1px solid var(--color-gray-100);
    color: var(--color-gray-400);
  }

  &.active {
    background-color: var(--color-blue-500);
    color: var(--color-white);
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: var(--size-4);

  & > * {
    flex-grow: 1;
  }
}
