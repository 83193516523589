.page {
  padding-top: var(--size-128);
  padding-bottom: var(--size-80);

  @media (--viewport-md) {
    padding-top: var(--size-164);
  }

  & > :not(:first-child) {
    margin-top: var(--size-32);

    @media (--viewport-lg) {
      margin-top: var(--size-80);
    }
  }
}

.head {
  color: var(--accent-color);

  & > .imageLayout {
    margin-top: var(--size-32);
    max-height: 570px;

    @media (--viewport-md) {
      margin-top: var(--size-80);
    }
  }
}
