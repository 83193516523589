.componentCalculator {
  padding: var(--size-48) var(--size-16);
  border-radius: var(--radius-12);
  color: var(--color-blue-500);
  background-color: var(--color-gray-100);

  @media (--viewport-lg) {
    padding-inline: var(--size-24);
  }

  & > .headingLayout {
    margin-bottom: var(--size-20);
  }
}
