.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-16);
  grid-template-areas:
    "content"
    "recruiter";

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: "content recruiter";
  }

  & > .content {
    grid-area: content;
  }

  & > .recruitersAndImageLayout {
    grid-area: recruiter;
  }
}

.title {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-40);
  font-style: italic;
  letter-spacing: -1px;
  color: var(--accent-color);
}

.content {
  background-color: var(--background-color);
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-40);
  }
}

.componentRecruiters {
  position: relative;
  background-color: var(--secondary-background-color);
  color: var(--primary-element-color);
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: var(--size-24);

  @media (--viewport-md) {
    padding: var(--size-40);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-white--20);
  }

  & > .imageRecruiter {
    width: 90px;
    height: 90px;
    align-self: flex-start;

    @media (--viewport-md) {
      align-self: unset;
    }
  }
}

.imageRecruiter {
  border-radius: var(--radius-4);
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.contentRecruiter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links {
  display: flex;
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-8);
  }
}
