.component {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  grid-gap: var(--size-64);

  &.left {
    & > .text {
      @media (--viewport-lg) {
        grid-row: 1 / 1;
        grid-column: 2 / span 5;
      }
    }

    & > .video {
      @media (--viewport-lg) {
        grid-row: 1;
        grid-column: 8 / span 5;
      }
    }
  }

  &.right {
    & > .text {
      @media (--viewport-lg) {
        grid-row: 1 / 1;
        grid-column: 7 / span 5;
      }
    }

    & > .video {
      @media (--viewport-lg) {
        grid-row: 1;
        grid-column: 1 / span 5;
      }
    }
  }

  @media (--viewport-lg) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--size-16);
  }
}

.text {
  display: inline-grid;
  grid-auto-flow: row dense;
  grid-template-rows: max-content auto;
  gap: var(--size-32);
  font-size: var(--font-size-18);
  line-height: var(--line-height-text);

  & > .buttonLayout {
    align-self: start;
    justify-self: start;
  }
}

.video {
  position: relative;

  & > .videoLayout {
    @media (--viewport-lg) {
      position: sticky;
      top: 0;
    }
  }
}
