.component {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.item {
  display: flex;
  gap: var(--size-8);
  padding: var(--size-4) var(--size-12);
  border: 1px solid var(--color);
  color: var(--color);
  border-radius: var(--radius-20);
}
