.page {
  & > .intro,
  & > .subvakgebieden {
    margin-top: var(--size-80);

    @media (--viewport-md) {
      margin-top: var(--size-128);
    }
  }
}

.top {
  padding: var(--menu-padding) 0 var(--size-80);
  background-color: var(--background-color);
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--menu-padding) 0 var(--size-128);
  }

  & > .subvakgebieden {
    margin-top: var(--size-48);

    @media (--viewport-md) {
      margin-top: var(--size-96);
    }
  }
}

.intro {
  display: flex;
  justify-content: center;
  padding-bottom: var(--size-80);

  @media (--viewport-md) {
    padding-bottom: var(--size-128);
  }
}

.ctaToJobs {
  & > * {
    min-height: 500px;
  }
}
