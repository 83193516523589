.component {
  background-color: var(--background-color);
  color: var(--color);
  padding: var(--size-128) 0;
}

.content {
  & > .list {
    margin-top: var(--size-48);

    @media (--viewport-md) {
      margin-top: var(--size-80);
    }
  }
}

.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--size-80);

  @media (--viewport-sm) {
    flex-direction: row;
    gap: 0;
  }
}

.value {
  font-size: var(--font-size-132);
  font-size: var(--font-size-132-220);
  line-height: var(--line-height-heading);
}

.item {
  font-style: italic;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 120px;

  @media (--viewport-sm) {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
  }

  & > .label {
    justify-self: start;
    align-self: end;

    @media (--viewport-sm) {
      justify-self: end;
    }
  }
}
