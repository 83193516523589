.componentNavigation {
  overflow: hidden !important;
  padding: var(--size-16) 0;
  pointer-events: none;
  background: transparent;
  transform: translateY(var(--menu-offset, 0));
  transition:
    transform var(--duration-600) var(--ease-in-out),
    background var(--duration-1200) var(--ease);

  &.isOpen {
    pointer-events: auto;
    background: var(--color-black--10);
    transition-duration: var(--duration-600);
    transition-delay: var(--duration-250);
    overflow: auto !important;
  }
}
