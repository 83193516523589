.component {
  background-color: var(--shade-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius-12);
  padding: var(--size-16) var(--size-24);
  position: relative;

  & > .icon {
    width: 40px;
    height: 40px;
    position: static;
  }
}

.title {
  font-size: var(--font-size-20);
  font-style: italic;
  color: var(--color);
  font-weight: var(--font-weight-400);
}

.type {
  text-transform: uppercase;
  font-size: var(--font-size-20);
  color: var(--accent-color);
  font-style: italic;
}

.icon {
  border: 1px solid var(--color);
  border-radius: var(--radius-circular);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover,
  &:active {
    background-color: var(--tertiary-element-background-color-hover);
    color: var(--tertiary-element-color-hover);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
