.component {
  position: relative;
  z-index: 0;

  & > .gridLayout {
    margin: var(--size-32) 0;
  }

  & > .loadMore {
    margin: var(--size-32) 0;

    @media (--viewport-md) {
      margin: var(--size-56) 0;
    }
  }

  & > .filtersContainerLayout {
    position: relative;
    z-index: 1;
  }
}

.noResults {
  display: flex;
  justify-content: center;

  & > .noResultsLayout {
    margin: var(--size-64) 0;

    @media (--viewport-md) {
      margin: var(--size-128) 0;
    }
  }
}

.componentNoResults {
  text-align: center;

  & > .buttonLayout {
    margin-top: var(--size-16);
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-column-count, 1), 1fr);
  column-gap: var(--size-32);
  transition: opacity var(--duration-100) var(--ease-in-out);
}

.loading {
  opacity: 0.7;
}

.column {
  grid-auto-flow: row dense;
}

.componentGridItem {
  display: block;
  padding-bottom: 30px;
  break-inside: avoid;
}

.componentTagFilter {
  background-color: var(--background-color);
  padding: var(--size-24);
  border-radius: var(--radius-12);
}

.topicFilters {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}
