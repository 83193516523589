.button {
  color: var(--color);

  &:hover {
    color: var(--accent-color);
  }

  & > .icon {
    display: inline-block;
    width: 12px;
  }
}

.icon {
  color: var(--accent-color);
}

.expand {
  background-color: var(--background-color);
  color: var(--color);
  padding: var(--size-12);
  border-radius: var(--radius-4);

  & > .text {
    margin-top: var(--size-12);
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  transform: rotate(-180deg);
}

.text {
  line-height: var(--line-height-text);
}
