.componentImpl {
  & > .grid {

    @media (--viewport-sm) {
      max-width: 80%;
      width: 100%;
      margin: auto;
    }

    @media (--viewport-lg) {
      max-height: 880px;
      max-width: 100%;
    }
  }
}

.grid {
  position: relative;
  display: grid;
  z-index: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(0, var(--size-20)) auto auto auto auto;
  gap: var(--size-16);
  grid-template-areas:
    "tile-1 tile-1 ..."
    "tile-1 tile-1 tile-3"
    "tile-1 tile-1 tile-2"
    "tile-4 tile-4 tile-5"
    "tile-4 tile-4 ...";

  @media (--viewport-lg) {
    grid-template-columns: 0 minmax(auto, 4fr) 5fr 4fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "tile-1 tile-1 tile-1 ..."
      "tile-1 tile-1 tile-1 tile-2"
      "tile-3 tile-3 tile-4 tile-5";
  }

  @media (--viewport-xl) {
    grid-template-columns: minmax(0, var(--size-32)) minmax(auto, 5fr) 7fr 1fr 5fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "tile-1 tile-1 tile-1 ...     ..."
      "tile-1 tile-1 tile-1 tile-2  tile-2"
      "...    tile-3 tile-4 tile-5  tile-5";
  }

  &.isTopVideoActive {
    gap: 0;
    grid-template-areas:
      "tile-1 tile-1 tile-1"
      "tile-1 tile-1 tile-1"
      "tile-2 tile-3 tile-5";

    @media (--viewport-lg) {
      gap: var(--size-16);
      grid-template-areas:
        "tile-1 tile-1 tile-1 tile-1 tile-4"
        "tile-1 tile-1 tile-1 tile-1 tile-5"
        "tile-1 tile-1 tile-1 tile-1 tile-3";
    }
  }

  &.isBottomVideoActive {
    gap: 0;
    grid-template-areas:
      "tile-5 tile-5 tile-5"
      "tile-5 tile-5 tile-5"
      "tile-3 tile-1 tile-2";

    & > .tile4Layout {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    @media (--viewport-lg) {
      gap: var(--size-16);
      grid-template-areas:
        "tile-5 tile-5 tile-5 tile-5 tile-5"
        "tile-5 tile-5 tile-5 tile-5 tile-5"
        "tile-3 tile-1 tile-2 tile-4 ...";
    }
  }

  & > * {
    height: 100%;
    width: 100%;
  }

  & > .tile1Layout {
    grid-area: tile-1;
    z-index: 1;
  }

  & > .tile2Layout {
    grid-area: tile-2;
    z-index: 1;
  }

  & > .tile3Layout {
    grid-area: tile-3;
    z-index: -1;

    @media (--viewport-lg) {
      z-index: 1;
    }
  }

  & > .tile4Layout {
    grid-area: tile-4;
    z-index: 1;
  }

  & > .tile5Layout {
    grid-area: tile-5;
    z-index: -1;
    height: auto;

    @media (--viewport-lg) {
      z-index: 0;
    }
  }

  & > .button {
    position: static;
  }
}

.videoInner {
  position: relative;
  z-index: 0;

  & > .triggerButton {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    inset: 0;
  }

  & > * {
    height: 100%;
  }
}

.componentVideoTileImpl {
  position: relative;
  pointer-events: auto;
  transition: transform var(--duration-450) var(--ease);
  border-radius: var(--radius-12);

  &:focus-within {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: 2px;
  }

  &:nth-of-type(1) {
    transform: translate(30%, 5%);
    aspect-ratio: 3 / 4;

    &.flat {
      aspect-ratio: unset;
    }

    & > .videoLayout {
      height: 100%;
    }

    @media (--viewport-lg) {
      transform: translate(20%, 5%);
      aspect-ratio: 7 / 9;
    }
  }

  &:nth-of-type(5) {
    transform: translate(0%, -20%);
    aspect-ratio: 3 / 4;

    &.flat {
      aspect-ratio: 16 / 9;
    }
  }

  &.compact {
    transform: translate(0%, 0%);
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  & > .videoTile {
    width: 100%;
    height: 100%;
  }

  & > .buttonLayout {
    position: absolute;
    top: calc(-1 * var(--size-48));
    left: 0;
    width: var(--size-32);
    height: var(--size-32);

    @media (--viewport-lg) {
      top: calc(-1 * var(--size-32));
      left: calc(-1 * var(--size-32));
    }
  }
}

.componentImageTile {
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &:nth-of-type(2) {
    transform: translate(0%, -110%);

    & > * {
      width: 100%;
      height: 100%;
    }

    @media (--viewport-lg) {
      display: flex;
      align-items: flex-end;
    }
  }

  &:nth-of-type(3) {
    transform: translate(-215%, -30%);

    @media (--viewport-lg) {
      transform: translate(-40%, -230%);
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &:nth-of-type(4) {
    display: flex;
    justify-content: flex-end;
    transform: translate(-20%, -30%);

    @media (--viewport-lg) {
      transform: translate(-130%, -60%);
    }

    & > * {
      width: 80%;

      @media (--viewport-lg) {
        width: 100%;
      }
    }
  }
}

.componentCloseButton {
  color: var(--accent-color);
  border: 1px solid var(--accent-color);
  border-radius: 50%;
  padding: var(--size-8);

  &:hover {
    background-color: var(--secondary-background-color);
    color: var(--secondary-element-color);
  }
}

.componentBaseImpl {
  transition: transform var(--duration-450) var(--ease);
  border-radius: var(--radius-12);

  &.compact {
    transform: translate(0, 0);
  }
}

.videoTile {
  overflow: hidden;

  & > .children {
    width: 100%;
    height: 100%;
  }
}

.children {
  & > * {
    width: 100%;
    height: 100%;
  }
}
