.component {
  overflow: hidden;
}

.slider {
  --slides-per-view: 1.2;

  @media (--viewport-sm) {
    --slides-per-view: 1.4;
  }

  @media (--viewport-md) {
    --slides-per-view: 1.8;
  }

  @media (--viewport-lg) {
    --slides-per-view: 2.8;
  }

  display: grid;
  grid-auto-flow: column;
  overflow: visible !important;
  padding-right: var(--size-164);
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .slideLayout {
    width: auto !important;
    margin: 4px;
  }
}

.header {
  color: var(--color-blue-500);
  padding-block: var(--size-32);
  padding-left: var(--size-32);
}

.headerText {
  font-size: var(--font-size-18);
  line-height: var(--line-height-text-sm);
  color: var(--color);
}

.componentSlide {
  --container-width: calc(min(100vw, var(--max-width)) - (2 * var(--container-padding)));
  --slide-width: calc(var(--container-width) / var(--slides-per-view));
  --spacing: var(--size-16);

  aspect-ratio: 4 / 5;
  border-radius: var(--radius-4);
  overflow: visible !important;
  pointer-events: none;

  @media (--viewport-md) {
    aspect-ratio: 9 / 10;
  }

  & > * {
    width: var(--slide-width);
    height: 100%;
  }

  &:not(:last-child) {
    & > .storyCardLayout {
      margin-right: var(--spacing);
    }
  }

  &:active,
  &:focus {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: 2px;
  }
}

.componentCard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: var(--size-32);
  border-radius: var(--radius-4);
  background-color: var(--background-color);
  aspect-ratio: 1;

  & > .title {
    margin-bottom: auto;
  }
}

.stepTitle {
  color: var(--accent-color);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-48);
  font-style: italic;
  letter-spacing: -2px;
  font-weight: var(--font-weight-400);
}

.title {
  color: var(--accent-color);
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-48);
  font-style: italic;
  letter-spacing: -2px;
  font-weight: var(--font-weight-400);
}

.text {
  font-size: var(--font-size-18);
  line-height: var(--line-height-text-sm);
  color: var(--color);
}
