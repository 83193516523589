.component {
  display: flex;
  align-items: center;
  gap: var(--size-16);
  container-type: inline-size;

  & > .avatar {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;

    @container (width >= 360px) {
      width: 64px;
      height: 64px;
    }
  }
}

.avatar {
  border-radius: var(--radius-circular);
  overflow: hidden;

  @container (width < 240px) {
    display: none;
  }

  & > * {
    width: 100%;
    height: 100%;
  }
}

.name {
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
}
