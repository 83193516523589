.page {
  background-color: var(--background-color);
  padding-top: var(--menu-padding-sm);

  @media (--viewport-md) {
    padding-top: var(--menu-padding);
  }

  & > .gridAndFilterLayout {
    margin-top: var(--size-24);
  }

  & > .introSection {
    margin-top: var(--size-16);
  }
}

.header {
  color: var(--color-blue-500);
}

.header,
.introSection {
  text-align: center;
}
