.form {
  display: grid;
  gap: var(--size-32) 0;
}

.recaptchaNotice {
  color: var(--color-light);
  font-size: var(--font-size-14);
  line-height: var(--line-height-text);

  & a {
    text-decoration: underline;
  }
}

.componentFilterHeader {
  font-size: var(--font-size-18);
  font-style: italic;
}

.filterGroup {
  & > .filterHeaderLayout {
    margin-bottom: var(--size-8);
  }
}

.componentFilterValues {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-8);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.componentInputsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-8);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
    gap: var(--size-8) var(--size-16);
  }
}

.componentSuccessMessage {
  display: grid;
  gap: var(--size-16) 0;
}
