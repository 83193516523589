.componentBase {
  --opacity: 0;

  background-color: var(--background-color);
  padding: var(--size-16);
  border-radius: var(--radius-4);
  position: relative;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewport-md) {
    padding: var(--size-24);
  }

  &.withPermanentIcon {
    --opacity: 0.8;
  }

  & > .icon {
    width: var(--size-40);
    height: var(--size-40);
    flex-shrink: 0;
    margin-left: var(--size-16);

    @media not (--viewport-md) {
      display: none;
    }
  }

  &:hover {
    border: 1px solid var(--accent-color);

    & > .icon {
      --opacity: 1;
    }
  }
}

.withPermanentIcon {
  transition: opacity var(--duration-200) var(--ease-out-sine);
}

.icon {
  color: var(--background-color);
  border: 1px solid currentColor;
  background-color: var(--accent-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: var(--opacity);
}
