.component {
  overflow: hidden;
}

.slider {
  padding-right: var(--size-164);
  overflow: visible !important;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  & > .slide {
    min-width: calc(100% / 1.2);
    max-width: calc(100% / 1.2);

    @media (--viewport-md) {
      min-width: calc(100% / 1.8);
      max-width: calc(100% / 1.8);
    }

    @media (--viewport-lg) {
      min-width: calc(100% / 2.8);
      max-width: calc(100% / 2.8);
    }
  }
}

.slide {
  aspect-ratio: 3 / 4;
  overflow: unset !important;

  & > * {
    height: 100%;
  }
}
