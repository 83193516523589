.componentBase {
  z-index: 0;
  position: relative;

  & > .playerContainer {
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  & > .imageLayout {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .previewPlayerLayout {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .overlay {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .additionalContent {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  & > .playButtonLayout {
    position: absolute;
    z-index: 5;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

.componentPortret {
  --aspect-ratio: calc(9 / 16);

  position: relative;
  padding-top: calc(100% / var(--aspect-ratio));
  background-color: var(--color-gray-100);

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentSquare {
  --aspect-ratio: calc(4 / 3);

  position: relative;
  padding-top: calc(100% / var(--aspect-ratio));
  background-color: var(--color-gray-100);

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentDefaultPlayerContainer {
  --aspect-ratio: calc(16 / 9);

  position: relative;
  padding-top: calc(100% / var(--aspect-ratio));
  background-color: var(--color-gray-100);

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.componentWavemakersPlayerContainer {
  --aspect-ratio: calc(9 / 16);

  @media (--viewport-md) {
    --aspect-ratio: calc(16 / 9);
  }

  position: relative;
  padding-top: calc(100% / var(--aspect-ratio));
  background-color: var(--color-black);

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.wavemakersPlayerContainer {
  border-radius: var(--radius-20);
  overflow: hidden;
}

.overlay {
  background-color: var(--color-gray-900--25);
  pointer-events: auto;
}

.componentWavemakers {
  border-radius: var(--radius-20);
  overflow: hidden;

  & > .additionalContent {
    height: 50%;
  }
}

.additionalContentWavemakers {
  justify-content: center;
  align-content: end;
  padding: 0;
}

.additionalContent {
  display: grid;
  color: var(--color);
  text-align: center;
  transition: opacity var(--motion-450) var(--ease-in-out);
  opacity: 1;

  &.isPlaying {
    opacity: 0;
  }

  & > * {
    max-width: 25ch;
    text-wrap: balance;
  }
}
