.componentDefault,
.componentArticle,
.componentTechblog,
.componentTopic,
.componentSubvakgebied,
.componentWavemakers,
.componentSimple {
  & > .itemLayout {
    background-color: var(--background-color);
    margin-top: var(--size-80);

    @media (--viewport-md) {
      margin-top: var(--size-128);
    }

    &:first-child[data-style-context='light-alt'] {
      margin-top: 0;
    }

    &[data-style-context='light-alt'] {
      padding-top: var(--size-80);
      padding-bottom: var(--size-80);

      @media (--viewport-md) {
        padding-top: var(--size-128);
        padding-bottom: var(--size-128);
      }
    }

    & + .item--ctaWithTitleAndButtonLayout {
      padding-block: var(--size-40);
    }
  }

  & > .itemLayout[data-style-context='light-alt'] + .itemLayout[data-style-context='light-alt'] {
    margin-top: 0;
    padding-top: 0;
  }

  & > .item--blocksLayout + .item--downloadLayout {
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-80);
    }
  }

  & > .item--blocksLayout + .item--cookiePermissionsLayout,
  & > .item--cookiePermissionsLayout + .item--blocksLayout,
  & > .item--blocksLayout + .item--inlineImageLayout,
  & > .item--inlineImageLayout + .item--blocksLayout,
  & > .item--codeBlockLayout + .item--quickLinksLayout,
  & > .item--blocksLayout + .item--quickLinksLayout,
  & > .item--quickLinksLayout + .item--blocksLayout,
  & > .item--blocksLayout + .item--definitionListLayout,
  & > .item--definitionListLayout + .item--blocksLayout,
  & > .item--blocksLayout + .item--tagsLayout,
  & > .item--tagsLayout + .item--blocksLayout,
  & > .item--quickLinksLayout + .item--tagsLayout,
  & > .item--tagsLayout + .item--quickLinksLayout,
  & > .item--blocksLayout + .item--linksBlockLayout,
  & > .item--linksBlockLayout + .item--blocksLayout {
    margin-top: var(--size-20);

    @media (--viewport-md) {
      margin-top: var(--size-40);
    }
  }

  & > .item--blocksLayout + .item--imagesWithContentSlidesLayout,
  & > .item--imagesWithContentSlidesLayout + .item--faqsLayout {
    margin-top: 0;
  }

  & > .item--videoPreviewAnchorLayout {
    margin: 0;
  }

  & > .item--blocksLayout + .item--codeBlockLayout,
  & > .item--codeBlockLayout + .item--blocksLayout {
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-64);
    }
  }

  & > *:last-child {
    margin-bottom: var(--size-80);

    @media (--viewport-md) {
      margin-bottom: var(--size-128);
    }

    &[data-style-context='light-alt'] {
      margin-bottom: 0;
    }
  }

  & > .item--factsAndFiguresLayout + .item--ctaToTraineeshipLayout {
    margin-top: 0;
  }

  & > .item--ctaToTraineeshipLayout + .item--factsAndFiguresLayout {
    margin-top: 0;
  }
}

.componentArticle {
  & > .itemLayout {
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-64);
    }
  }

  & > .item--tilesLayout {
    margin-top: var(--size-56);

    @media (--viewport-md) {
      margin-top: var(--size-80);
    }

    & + * {
      margin-top: var(--size-56);

      @media (--viewport-md) {
        margin-top: var(--size-80);
      }
    }
  }

  & > *:last-child {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-64);
    }
  }
}

.componentTopic {
  & > .itemLayout:first-child {
    margin-top: var(--size-32);
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.componentPanel,
.componentTopic,
.componentJobAlert {
  & > .itemLayout {
    &:not(:first-child) {
      margin-top: var(--size-32);
    }
  }
}

.componentFaq {
  & > .itemLayout {
    &:not(:first-child) {
      margin-top: var(--size-64);

      @media (--viewport-md) {
        margin-top: var(--size-80);
      }
    }
  }
}

.componentTechblog {
  & > .itemLayout:first-child {
    margin-top: var(--size-20);

    @media (--viewport-md) {
      margin-top: var(--size-40);
    }
  }

  & > .item--blocksLayout + .item--ctaWithTitleAndButtonLayout,
  & > .item--ctaWithTitleAndButtonLayout + .item--blocksLayout {
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-80);
    }
  }
}

.componentWavemakers {
  position: relative;
  z-index: 0;

  & > .item--videoPreviewLayout {
    z-index: 1;
    position: fixed;
    bottom: var(--size-20);
    left: var(--size-20);
  }
}

.componentVideoPreviewContainer {
  background-color: unset !important;
}
