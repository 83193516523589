.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-32);
  color: var(--accent-color);
  overflow: visible;

  @media (--viewport-md) {
    display: none;
  }

  & > .slider {
    overflow: visible;
    width: 100%;
  }

  & > .sliderButtonsLayout {
    align-self: flex-end;
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  text-align: center;

  & > .text {
    max-width: 600px;
  }
}

.text {
  color: var(--color);
  font-size: var(--font-size-18);
  line-height: var(--line-height-text-sm);
}

.slider {
  display: flex;
  padding-top: var(--size-12);
  cursor: grab;
  overflow: visible;

  &:active {
    cursor: grabbing;
  }

  &.sliderDisabled {
    flex-direction: column;
    gap: var(--size-8);
    cursor: auto;

    @media (--viewport-sm) {
      flex-direction: row;
      gap: var(--size-16);
    }

    @media (--viewport-lg) {
      gap: var(--size-32);
    }

    & > * {
      flex: 1;
    }
  }

  & > .slideContainer {
    height: auto;
    min-width: 80%;
  }
}

.slideContainer {
  aspect-ratio: 3 / 4;

  & > * {
    height: 100%;
  }

  & > .calculatorLayout {
    width: 100%;
  }
}
