.component {
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-48);

  @media (--viewport-md) {
    padding: var(--size-48) var(--size-40);
    flex-direction: row;
    align-items: center;
    gap: var(--size-24);
  }
}

.componentTechblog {
  align-items: flex-start;
  flex-direction: column;
  gap: var(--size-24);

  & > .headingLayout {
    margin-top: var(--size-48);
  }
}

.componentBase {
  display: flex;
  padding: var(--size-24);
  background-color: var(--background-color);
  border-radius: var(--radius-4);
  color: var(--accent-color);
}
