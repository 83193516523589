.component {
  background: var(--shade-color);
  padding: var(--size-24);
  border-radius: var(--radius-4);

  & > .header {
    margin-bottom: var(--size-24);
  }

  @media (--viewport-md) {
    padding: var(--size-40);
  }
}

.biography {
  font-size: var(--font-size-18);
  line-height: var(--line-height-text);
}

.heading {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-40);
  font-style: italic;
  line-height: var(--line-height-heading);
  color: var(--accent-color);
}

.biographies {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.componentBiography {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);

  &:not(:first-child) {
    border-top: 1px var(--hairline-color) solid;
    padding-top: var(--size-24);
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--size-8);

  @media (--viewport-md) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  gap: var(--size-8);
}
