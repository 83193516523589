.list {
  display: grid;
  gap: var(--size-16);

  @media (--viewport-md) {
    grid-template-areas:
      '...    tile-2 tile-2 ...    ...'
      'tile-1 tile-2 tile-2 tile-3 tile-3'
      'tile-4 tile-2 tile-2 tile-3 tile-3'
      'tile-4 tile-2 tile-2 tile-3 tile-3'
      'tile-4 tile-5 tile-6 tile-6 amp';

    grid-template-columns: 2fr 3fr 1fr 2fr 1fr;
  }

  @media (--viewport-lg) {
    grid-template-areas:
      '...    ...    ...    ...    ...    ...'
      '...    tile-2 ...    tile-4 ...    ...'
      'tile-1 tile-2 ...    tile-4 ...    tile-6'
      'tile-1 tile-2 tile-3 tile-4 ...    tile-6'
      '...    tile-2 tile-3 tile-5 tile-5 tile-6'
      '...    ...    tile-3 tile-5 tile-5 ...';
    grid-template-columns: 2fr 4fr 3fr 3fr var(--size-48) 2fr;
  }

  & > * {
    min-width: 0;
    min-height: 0;
  }

  & > .amp {
    @media (--viewport-md) {
      grid-area: amp;
    }
  }

  & > .amp-2 {
    @media (--viewport-md) {
      grid-area: amp-2;
    }
  }

  & > [data-context-tile-index='1'] {
    @media (--viewport-md) {
      grid-area: tile-1;
    }
  }

  & > [data-context-tile-index='2'] {
    @media (--viewport-md) {
      grid-area: tile-2;
    }
  }

  & > [data-context-tile-index='3'] {
    @media (--viewport-md) {
      grid-area: tile-3;
    }
  }

  & > [data-context-tile-index='4'] {
    @media (--viewport-md) {
      grid-area: tile-4;
    }
  }

  & > [data-context-tile-index='5'] {
    @media (--viewport-md) {
      grid-area: tile-5;
      justify-self: end;
      align-self: start;
      width: 100%;
    }

    @media (--viewport-lg) {
      width: calc((2 / 3) * (100% - var(--size-48) - var(--size-16)));
    }
  }

  & > [data-context-tile-index='6'] {
    @media (--viewport-md) {
      grid-area: tile-6;
      width: 66.66%;
      align-self: start;
    }

    @media (--viewport-lg) {
      width: 100%;
      align-self: end;
    }
  }
}

.component,
.componentHero {
  @media (--viewport-md) {
    grid-template-columns: 2fr 3fr 1fr 2fr 1fr;
    grid-template-areas:
      '...    tile-2 tile-2 ...    ...'
      'tile-1 tile-2 tile-2 tile-3 tile-3'
      'tile-6 tile-2 tile-2 tile-3 tile-3'
      'tile-4 tile-2 tile-2 tile-3 tile-3'
      'tile-4 tile-5 tile-6 tile-6 amp';
  }

  @media (--viewport-lg) {
    grid-template-columns: 2fr 3fr 4fr 3fr var(--size-48) 2fr;
    grid-template-areas:
      '...    ...    tile-3 ...    ...    ...'
      '...    ...    tile-3 tile-4 ...    ...'
      'tile-1 ...    tile-3 tile-4 ...    tile-6'
      'tile-1 tile-2 tile-3 tile-4 ...    tile-6'
      'tile-1 tile-2 tile-3 tile-5 tile-5 tile-6'
      '...    tile-2 amp    tile-5 tile-5 ...';
  }
}

.componentFooter {
  background-color: var(--color-blue-900);
  display: flex;
  flex-wrap: nowrap;
  gap: var(--size-16);
  flex-direction: column;
  text-align: left;

  & > * {
    @media (--viewport-md) {
      /* Using margin instead of gap because we needed more rows to force correct layout */
      margin: var(--size-8);
    }
  }


  @media (--viewport-md) {
    display: grid;

    /* Using margin instead of gap because we needed more rows to force correct layout */
    gap: 0;
    grid-template-rows: repeat(12, min-content);
    grid-template-columns:
      0.1fr   0.3fr   0.3fr   0.3fr   0.3fr   0.1fr;
    grid-template-areas:
      '...    ...    tile-1 tile-1  ...    ...    '
      '...    amp    tile-1 tile-1 amp-2    ...    '
      'tile-3 tile-3 tile-1 tile-1 tile-6  tile-6 '
      'tile-3 tile-3 tile-4 tile-5 tile-6  tile-6 '
      'tile-3 tile-3 tile-4 tile-5 tile-6  tile-6 '
      'tile-3 tile-3 tile-4 tile-5 tile-6  tile-6 '
      'tile-7 tile-7 tile-4 tile-5 tile-10 tile-10'
      'tile-7 tile-7 tile-4 tile-5 tile-10 tile-10'
      'tile-7 tile-7 tile-8 tile-9 tile-10 tile-10'
      'tile-7 tile-7 tile-8 tile-9 tile-10 tile-10'
      'tile-7 tile-7 tile-8 tile-9 tile-10 tile-10'
      'tile-7 tile-7 ...    ...    tile-10 tile-10';
  }

  @media (--viewport-lg) {
    grid-template-rows: repeat(14, min-content);
    grid-template-columns:
      0.1fr    0.3fr  0.1fr   0.3fr   0.3fr   0.3fr   0.3fr   0.1fr   0.3fr   0.1fr;
    grid-template-areas:
      '...     ...     ...    ...     tile-1  tile-1  ...     ...     ...     ...    '
      '...     ...     ...    amp     tile-1  tile-1  amp-2    ...     ...     ...    '
      '...     tile-3 tile-4  tile-4  tile-1  tile-1  tile-7  tile-7  tile-8  ...    '
      '...     tile-3 tile-4  tile-4  tile-5  tile-6  tile-7  tile-7  tile-8  ...    '
      'tile-9  tile-9 tile-4  tile-4  tile-5  tile-6  tile-7  tile-7  tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-5  tile-6  tile-13 tile-13 tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-5  tile-6  tile-13 tile-13 tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-11 tile-12 tile-13 tile-13 tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-11 tile-12 tile-13 tile-13 tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-11 tile-12 tile-13 tile-13 tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-11 tile-12 tile-13 tile-13 tile-14 tile-14'
      'tile-9  tile-9 tile-10 tile-10 tile-11 tile-12 tile-13 tile-13 tile-14 tile-14'
      '...     ...    tile-10 tile-10 tile-11 tile-12 tile-13 tile-13 ...     ...    ';
  }


  & > .gridFooterSkillsMatchButtonLayout {
    width: 100%;

    @media (--viewport-md) {
      grid-area: amp;
      width: 66.66%;
      align-self: end;
      justify-self: end;
    }
  }

  & > .gridFooterCalculatorButtonLayout {
    width: 100%;

    @media (--viewport-md) {
      grid-area: amp-2;
      width: 66.66%;
      align-self: end;
    }
  }
}

.skillsMatchButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-900);
  background-color: var(--color-white);
  border-radius: var(--radius-12);
  font-family: var(--font-family-base);
  font-size: var(--font-size-12);
  font-size: var(--font-size-28-32);
  font-weight: var(--font-weight-400);
  font-style: italic;
  aspect-ratio: 4 / 1;

  &:focus-within {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: 2px;
    border-radius: var(--radius-8);
  }

  @media (--viewport-md) {
    aspect-ratio: 5 / 7;
  }

  & > .skillsMatchButtonLayout {
    position: absolute;
    inset: var(--size-8);
  }
}

.componentGridFooterTile {
  transform-origin: top center;

  & > .rewardCalculatorButtonLayout {
    width: 100%;
  }
}
