.component {
  display: flex;
  gap: var(--size-16);

  @media not (--viewport-xs) {
    gap: var(--size-4);
  }

  & > .iconButtonLayout {
    @media (--viewport-xs) {
      width: 100%;
    }
  }
}

.componentIconButtonImpl {
  background-color: var(--background-color);
  position: relative;
  border-radius: var(--radius-4);

  & > .indicatorLayout {
    position: absolute;
    right: 0;
    top: 0;
    margin: 4px;
    width: 22px;
    height: 22px;
  }
}

.buttonText {
  @media not (--viewport-xs) {
    display: none;
  }
}

.componentFilterIndicator {
  background-color: var(--color-gray-100);
  outline: 2px solid var(--color-white);
  padding: 2px;
  border-radius: var(--radius-4);
  font-size: var(--font-size-12);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconButtonContent {
  display: flex;
  justify-content: center;
  padding: var(--size-16);
  align-items: center;

  & > .iconLayout {
    flex-shrink: 0;

    @media (--viewport-xs) {
      margin-right: var(--size-16);
    }
  }
}

.componentOverlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-24);
  border-bottom: 1px solid var(--border-color);

  & > .overlayHeaderButton {
    width: var(--size-32);
    height: var(--size-32);
  }
}

.componentFiltersOverlayContent {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--background-color);

  & > .resultsButton {
    position: fixed;
    bottom: var(--size-16);
    left: 50%;
    min-width: 200px;
  }
}

.resultsButton {
  transform: translateX(-50%);
}

.component_rootFiltersOverlay {
  height: 100%;

  & > .filtersOverlayContentLayout {
    height: 100%;
  }
}

.jobFilters {
  padding-bottom: 56px;
  overflow: scroll;
}

.overlayHeaderTitle {
  font-size: var(--font-size-22);
  font-style: italic;
}

.overlayHeaderButton {
  background-color: var(--color-light);
  color: var(--color-white);
  padding: var(--size-8);
  border-radius: var(--radius-12);
}

.component_rootSearchOverlay {
  padding-top: var(--size-96);
  background-color: var(--color-gray-900--25);
  height: 100%;
}
