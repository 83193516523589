.component {
  background-color: var(--background-color);
  padding: var(--size-80) 0;

  @media (--viewport-lg) {
    padding: var(--size-128) 0;
  }
}

.inner {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: var(--radius-4);
  overflow: hidden;

  @media (--viewport-lg) {
    flex-direction: row;
  }

  & > .content {
    order: -1;
    width: 100%;

    @media (--viewport-lg) {
      order: 0;
      width: 50%;
    }
  }

  & > .imageLayout {
    max-height: 350px;
    margin-top: var(--size-16);
    width: 100%;

    @media (--viewport-lg) {
      margin-top: 0;
      max-height: 500px;
      width: 50%;
    }
  }
}

.content {
  background-color: var(--background-color);
  color: var(--accent-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--size-24);

  @media (--viewport-lg) {
    padding: var(--size-48);
  }

  @media (--viewport-xl) {
    padding: var(--size-64);
  }

  & > .headingLayout {
    margin-bottom: var(--size-24);
  }

  & > .textLayout {
    margin-bottom: var(--size-16);
  }

  & > .bottomLayout {
    margin-top: var(--size-32);
  }
}

.subtitle {
  color: var(--color);
  font-style: italic;
}

.subText {
  color: var(--color);
}

.componentBottom {
  border-top: 1px solid var(--border-color);
  padding-top: var(--size-32);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
