.component {
  color: var(--color);
  background-color: var(--background-color);
  border-radius: var(--radius-12);
  padding: var(--size-24);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-24);

  @media (--viewport-sm) {
    flex-direction: row;
    padding: var(--size-32);
    gap: var(--size-40);
  }

  @media (--viewport-md) {
    padding: var(--size-64);
    gap: var(--size-80);
  }

  @media (--viewport-lg) {
    padding: var(--size-80);
    gap: var(--size-128);
  }

  @media (--viewport-xl) {
    padding: var(--size-80);
    gap: var(--size-164);
  }

  & > .image {
    order: -1;
    flex-basis: 100%;

    @media (--viewport-sm) {
      flex: 1 0 180px;
    }

    @media (--viewport-md) {
      flex-basis: 280px;
    }

    @media (--viewport-lg) {
      flex-basis: 340px;
    }
  }
}

.heading {
  color: var(--accent-color);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.button {
  & > * {
    width: 100%;

    @media (--viewport-sm) {
      width: auto;
    }
  }
}

.text {
  font-size: var(--font-size-18);
  line-height: var(--line-height-text);
}

.image {
  border-radius: var(--radius-12);
  overflow: hidden;
}
