.component {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (--viewport-lg) {
    padding-right: var(--size-40);
  }

  & > .heading {
    margin-bottom: var(--size-32);
  }

  & > .employees {
    margin-top: var(--size-32);
  }

  & > .button {
    margin-top: var(--size-32);
  }
}

.heading {
  color: var(--accent-color);
}

.employees {
  font-size: var(--font-size-14);
  line-height: var(--line-height-text);
}
