.component_rootTechblog {
  height: 100%;
}

.componentBase {
  --overlay-opacity: 0;
  --arrow-opacity: 0;

  container-type: inline-size;

  &:hover,
  &:focus {
    --overlay-opacity: 0.1;
    --arrow-opacity: 1;
  }
}

.layout {
  --padding: var(--size-8);

  position: relative;
  height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: var(--radius-12);
  overflow: hidden;
  padding: var(--padding);
  padding-top: var(--size-164);
  background-color: var(--background-color);
  color: var(--color);

  &:focus-within {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: 2px;
  }

  @media (--viewport-md) {
    aspect-ratio: 3 / 4;
  }

  @container (width >= 164px) {
    --padding: var(--size-16);
  }

  @container (width >= 240px) {
    --padding: var(--size-24);
  }

  & > .labelContainer {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  & > .topSlot {
    order: -1;
  }

  & > .heading {
    position: static;
    z-index: 1;
  }

  & > .bottomSlot {
    margin-top: var(--padding);
  }

  & > .image {
    position: absolute;
    z-index: -2;
    inset: 0;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    opacity: var(--overlay-opacity);
    background-color: var(--color-black);
    transition: opacity var(--duration-200);
  }
}

.heading {
  position: relative;

  & > .title {
    position: static;
  }
}

.title {
  display: grid;
  position: relative;
  font-style: italic;
  font-size: var(--font-size-22-25);

  @container (width <= 180px) {
    font-size: var(--font-size-14);
  }

  &:focus,
  &:active {
    outline: none;
  }

  &::before,
  & > * {
    grid-area: 1 / 1 / -1 / -1;
  }

  &::before {
    content: '';
    display: block;
    margin-left: calc(-1 * var(--padding));
    margin-right: calc(-1 * var(--padding));
    height: 400px;
    height: max(400px, calc(150% + 200px));
    align-self: end;
    margin-bottom: -200px;
    background-image: var(--title-gradient);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.topSlot {
  font-style: italic;
  line-height: var(--line-height-heading);
}

.jobMetaContainer {
  display: flex;
  gap: var(--size-4) var(--size-8);
  flex-wrap: wrap;
}

.jobMetaItem {
  display: flex;
  gap: var(--size-8);
  white-space: nowrap;
  font-size: var(--font-size-14);

  &:not(:last-of-type) {
    &::after {
      content: '•';
      display: inline-block;
    }
  }
}

.componentImage,
.componentVideo {
  --title-gradient: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) max(50%, calc(100% - 200px)))
}

.componentQuote {
  & > .bottomSlot {
    @container (width < 200px) {
      display: none;
    }
  }
}

.statistic {
  font-size: var(--font-size-32);
  font-size: clamp(
    var(--font-size-32),
    var(--font-size-32) + 16 * (100cqw - 164px) / 196,
    var(--font-size-48)
  );

  @container (width < 160px) {
    display: none;
  }
}

.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-wrap: balance;

  @container (width >= 164px) {
    -webkit-line-clamp: 4;
  }
}

.labelContainer {
  pointer-events: none;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  gap: var(--size-8);
  padding: var(--size-8);

  & > .arrowContainer {
    grid-column: 3;
    height: 100%;
  }
}

.label {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-12) var(--size-8);
  padding: var(--size-12) max(var(--size-8), var(--padding) - var(--size-8));
  backdrop-filter: blur(var(--size-blur-10));
  background-color: var(--color-black--30);
  border-radius: var(--radius-4);
}

.arrowContainer {
  opacity: var(--arrow-opacity);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-12);
  backdrop-filter: blur(var(--size-blur-10));
  background-color: var(--color-black--10);
  border-radius: var(--radius-4);
  transition: opacity var(--duration-200);
}

.image {
  opacity: var(--image-opacity);

  & > * {
    width: 100%;
    height: 100%;
  }
}

.componentPodcastIcon {
  display: flex;
  gap: var(--size-8);
  align-items: center;
  padding-top: var(--size-12);

  & > .podcastIcon {
    width: 100%;
    height: var(--size-32);
  }

  & > .playIcon {
    flex-shrink: 0;
    flex-basis: var(--size-40);
    height: var(--size-40);
  }
}

.podcastIcon {
  background-image: url('/images/icons/podcast.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.playIcon {
  border: 1px solid var(--color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
