@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Input */
.componentInput {
  position: relative;

  & > .input {
    width: 100%;
  }

  & > .validationCheckLayout {
    position: absolute;
    top: 50%;
    right: var(--size-12);
    height: 100%;
    width: var(--size-24);
  }

  & > .checkboxInput {
    position: absolute;
  }
}

.input {
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  border-radius: var(--radius-4);
  border: 1px solid var(--color);
  background-color: var(--shade-color);

  &:focus {
    border-color: var(--color-blue-500);
    outline: 1px solid var(--color-blue-500);
  }

  &::placeholder {
    color: var(--color-light);
  }
}

/* Checkbox */
.checkbox,
.componentCheckbox {
  display: flex;
  position: relative;
  padding-left: var(--size-32);
  cursor: pointer;

  &:focus-within {
    .checkboxIndicator {
      outline: 2px solid var(--color-blue-500);
      border-radius: var(--radius-4);
    }
  }

  &.disabled {
    cursor: default;
  }

  & > .checkboxInput {
    position: absolute;
  }

  & > .checkboxIndicator {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
  }
}

.checkboxInput {
  opacity: 0;
}

.checkboxLabel {
  color: var(--color);
  font-size: var(--font-size-18);
  line-height: var(--line-height-text);

  & a {
    color: var(--color-accent);
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.checkboxLabelDescription {
  color: var(--color-light);
  font-size: var(--font-size-14);
  line-height: var(--line-height-text);
}

.checkboxLabelMain {
  display: flex;
  justify-content: space-between;
  line-height: var(--line-height-text);

  &.withAsterisk {
    &::after {
      content: ' *';
    }
  }
}

.checkboxIndicator {
  border: 1px solid var(--color);
  border-radius: var(--radius-4);
  padding: 3px;
}

.checkboxIndicatorChecked {
  border-color: var(--accent-color);
  background-color: var(--accent-color);
  color: var(--background-color);
}

/* File upload */
.fileUploadButtonContainer {
  position: relative;
  z-index: 0;
  padding: var(--size-16);
  border-radius: var(--radius-4);
  border: 1px solid var(--color);
  background-color: var(--shade-color);

  &:focus-within {
    border-color: var(--color-blue-500);
    outline: 1px solid var(--color-blue-500);
  }

  &:not(.fileUploadButtonContainerDisabled):hover {
    & > .uploadFieldButton > .uploadIcon {
      background-color: var(--color);
      color: var(--background-color);
    }
  }

  & > .inputFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.fileUploadRemove {
  display: flex;
  gap: var(--size-8);
  justify-content: space-between;
  align-items: center;
  padding: var(--size-16);
  border-radius: var(--radius-4);
  border: var(--shade-color);
  background-color: var(--shade-color);

  & > .uploadCloseIcon {
    width: var(--size-24);
  }
}

.uploadChevron {
  transform: translate(1px, 1px);
}

.inputFile {
  opacity: 0;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.uploadFieldButton {
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .uploadIcon {
    width: var(--size-32);
    height: var(--size-32);
  }
}

.uploadIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color);
  border-radius: var(--radius-circular);
}

.uploadTitle {
  font-size: var(--font-size-16);
  color: var(--link-color);
}

/* Phone input */
.componentPhoneNumber {
  position: relative;
  display: flex;
  background-color: var(--shade-color);
  z-index: 0;
  border-radius: var(--radius-4);
  border: 1px solid var(--color);

  &:focus-within {
    border-color: var(--color-blue-500);
    outline: 1px solid var(--color-blue-500);
  }

  &::placeholder {
    color: var(--color-light);
  }

  & > .dialCodeSelectButton {
    margin: 4px;
  }

  & > .divider {
    width: 1px;
    margin: var(--size-8) 2px;
  }

  & > .languageSelectLayout {
    flex-grow: 0;
  }

  & > .phoneContainer {
    flex: 1;
  }
}

.phoneContainer {
  position: relative;
  z-index: 0;

  & > .phoneInput {
    width: 100%;
  }

  & > .validationCheckLayout {
    position: absolute;
    top: 50%;
    right: var(--size-12);
    height: 100%;
    width: var(--size-24);
  }
}

.divider {
  background-color: var(--hairline-color);
  border: none;
}

.phoneInput {
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  background-color: var(--shade-color);
  border: none;
  border-radius: var(--radius-4);
}

.dialCodeSelectButton {
  padding: var(--size-12) var(--size-32) var(--size-12) var(--size-12);
  display: flex;
  gap: var(--size-8);
  align-items: center;

  & > .flagIcon {
    width: var(--size-24);
  }

  & > .dropdownChevron {
    width: var(--size-8);
  }
}

.dropdownChevron {
  transform: rotate(90deg);
}

.dropdownChevronOpen {
  transform: rotate(-90deg);
}

.dialCodeDropdown {
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-menu);
  padding: var(--size-8);
  overflow: auto;
}

.dialCodeOption {
  & > .dialCodeOptionButton {
    width: 100%;
  }
}

.dialCodeOptionButton {
  display: block;
  display: flex;
  gap: var(--size-12);
  justify-content: space-between;
  padding: var(--size-8) var(--size-12);

  @media (--viewport-lg) {
    gap: var(--size-16);
  }

  &[data-active='true'] {
    background-color: var(--shade-color);
  }
}

.dialCodeName {
  display: flex;
  gap: var(--size-8);

  & > .dialCodeIcon {
    width: var(--size-24);
  }
}

.dialCodeIcon {
  display: block;
}

/* Select Field */

.select {
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  border-radius: var(--radius-4);
  border: 1px solid var(--color);
  background-color: var(--shade-color);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectNoValue {
  color: var(--color-light);
}

.selectChevron {
  transform: rotate(90deg) translateX(2px);
}

.selectChevronOpen {
  transform: rotate(-90deg) translateX(-2px);
}

.selectDropdown {
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-menu);
  padding: var(--size-8);
  overflow: auto;
}

.selectOption {
  & > .selectOptionButton {
    width: 100%;
  }
}

.selectOptionButton {
  display: block;
  display: flex;
  gap: var(--size-12);
  justify-content: space-between;
  padding: var(--size-8) var(--size-12);

  @media (--viewport-lg) {
    gap: var(--size-16);
  }

  &[data-active='true'] {
    background-color: var(--shade-color);
  }
}

/* Date Input */
.dateField {
  display: flex;
  border-radius: var(--radius-4);
  border: var(--shade-color);
  background-color: var(--shade-color);

  & > .dateInput {
    flex: 1;
  }
}

.dateInput {
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  border: none;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.dateDropdownButton {
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dateDropdown {
  background-color: var(--shade-color);
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-menu);
  padding: var(--size-8);
  overflow: auto;
}

/* Day Picker */
.daypickerSelected {
  background-color: var(--accent-color);
  color: var(--color-white);
}

.daypickerButton {
  border-radius: var(--radius-4);
  padding: var(--size-8);

  &:not([aria-selected='true']):hover {
    background-color: var(--color-gray-300);
  }
}

.daypickerLabel {
  font-weight: normal;
  font-size: var(--font-size-22);
  font-style: italic;
}

.daypickerNav {
  display: flex;
  gap: var(--size-8);
}

.daypickerNavButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color);
  border-radius: var(--radius-circular);

  &:hover {
    background-color: var(--color-gray-900) !important;
    color: var(--color-white);
  }
}

.daypickerTableHead {
  font-weight: var(--font-weight-800);
}

.daypickerTable {
  font-size: var(--font-size-14);
}

/* Field with label and error */
.componentFieldWithLabelAndError {
  & > * {
    width: 100%;
  }

  & > .label {
    margin-bottom: var(--size-8);
  }

  & > .fileUploadRemove {
    min-height: var(--size-64);
  }
}

.label {
  display: block;
  line-height: var(--line-height-text);

  & > .labelDescription {
    margin: var(--size-8) 0;
  }
}

.labelDescription {
  font-size: var(--font-size-14);
  line-height: var(--line-height-text);
  color: var(--color-light);
}

.optional {
  font-size: var(--font-size-12);
  color: var(--color-light);
  font-style: normal;
}

.labelMain {
  display: flex;
  justify-content: space-between;
  color: var(--color);
  font-style: italic;
  font-size: var(--font-size-18);

  &.withAsterisk {
    position: relative;

    &::after {
      content: ' *';
    }
  }
}

/* Field with error */
.componentFieldWithError {
  & > .errorMessageLayout {
    margin-top: var(--size-8);
  }
}

/* Error message */
.componentErrorMessage {
  display: inline-flex;
  align-items: center;
  justify-content: safe flex-start;
  gap: var(--size-8);
  color: var(--color-error);
  font-size: var(--font-size-14);
  animation: fadeIn 0.2s;
  visibility: hidden;
  height: 0;

  &.isVisible {
    visibility: visible;
    height: auto;
  }

  & > .iconLayout {
    width: var(--size-12);
    height: var(--size-12);
  }
}

/* General */
.componentValidationCheck {
  width: var(--size-12) !important;
  height: var(--size-12) !important;
  color: var(--accent-color);
  transform: translateY(-50%);
}

.inputError {
  border: 1px solid var(--color-error);
}

.disabled {
  opacity: 0.5;
}
