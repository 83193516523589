.pageVerify,
.pageUnsubscribe {
  padding: var(--menu-padding) 0 var(--size-64);
  background-color: var(--background-color);
}

.header {
  color: var(--accent-color);
  text-align: center;

  & > .headerContainerLayout {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin-bottom: var(--size-32);
    }
  }
}

.content {
  & > .contentContainerLayout {
    max-width: 500px;
    margin: auto;
  }
}

.contentWrapper {
  display: grid;
  gap: var(--size-16);
  background-color: var(--color-white);
  padding: var(--size-24);
  border-radius: var(--radius-4);
}
