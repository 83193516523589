.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentBase {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .toggleContainer {
    width: var(--size-48);
    height: var(--size-24);
  }
}

.toggleContainer {
  --background: var(--color-gray-400);
  --transition: 0;
  --border-focus: 2px solid transparent;

  position: relative;
  display: inline-block;

  &::after {
    content: '';
    border: var(--border-focus);
    border-radius: var(--radius-20);
    display: block;
    position: absolute;
    inset: -4px;
  }

  &:has(:checked) {
    --background: var(--color-blue-500);
    --transition: calc(var(--size-48) / 2);
  }

  &:has(:focus-visible) {
    --border-focus: 2px solid var(--color-blue-500);
  }

  & > .slider {
    position: absolute;
    inset: 0;
  }
}

.slider {
  cursor: pointer;
  background-color: var(--background);
  transition: var(--duration-200);
  border-radius: var(--radius-20);
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: var(--size-20);
    width: var(--size-20);
    inset: 2px;
    background-color: var(--color-white);
    transition: transform var(--duration-200);
    border-radius: 50%;
    transform: translateX(var(--transition));
  }
}

.content {
  display: flex;
}

.inputToggle {
  border: none;
}
