.page {
  & > .containerLayout {
    margin-bottom: var(--size-80);

    @media (--viewport-md) {
      margin-bottom: var(--size-128);
    }
  }
}

.content {
  background-color: var(--background-color);
  padding-top: var(--menu-padding-sm);

  @media (--viewport-md) {
    padding-top: var(--menu-padding);
  }

  & > .link,
  & > .subHeaderLayout {
    margin-top: var(--size-32);
  }
}

.link {
  float: right;
}

.header {
  text-align: center;
}
