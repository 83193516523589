.component {
  & > .subHeaderLayout {
    margin-top: var(--size-32);
  }

  & > .link {
    margin-top: var(--size-32);
  }
}

.link {
  float: right;
}
