.componentBase {
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: var(--accent-color);
  }
}

.componentWithLabel {
  display: flex;
  gap: var(--size-12);
  align-items: center;
  text-decoration: none;

  &:focus-within {
    outline: 2px solid light-dark(
      var(--contrasting-card-border-color-light),
      var(--contrasting-card-border-color-dark)
    );
    outline-offset: var(--size-4);
    border-radius: var(--size-4);
    width: fit-content;
  }

  & > .iconContainer {
    width: var(--size-32);
    height: var(--size-32);
  }
}

.iconContainer {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color);
  border-radius: 50%;
  border: 1px solid var(--color);
}
