._rootLoader {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .loaderLayout {
    width: var(--size-48);
    height: var(--size-48);
  }
}
